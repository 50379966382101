import React from 'react';
import { IPage } from '../../types/pagesTypes';
import moment from 'moment';

type Props = {
  data: IPage
  handleEditClick(id: number | null): void
};

const PageListItem: React.FC<Props> = props => {
  const updatedAt = props.data.page_last_modified ?
          moment(props.data.page_last_modified * 1000).format("YYYY-MM-DD HH:mm:ss") :
          "";

  return (
    <tr onClick={() => props.handleEditClick(props.data.page_id)}>
      <td>{props.data.page_title}</td>
      <td>{updatedAt}</td>
    </tr>
  )
}

export default PageListItem;