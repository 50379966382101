import axios from '../config/axios';
import { Dispatch } from 'redux';
import * as actionTypes from '../constants/actionTypes';
import { errorHandle } from './errorHandle';
import { addNotification } from './notificationActions';
import { NotificationTypes } from '../types/notificationTypes';

export function getStylesAction() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.STYLES_UPDATING });

    axios.get('/group-styles')
      .then((result) => {
        dispatch({
          type: actionTypes.STYLES_RECEIVED,
          styles: result.data
        });
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.STYLES_UPDATED });
      })
  }
}


export function updateStyleAction(id: number, title: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.STYLES_UPDATING });

    axios.patch(`/group-styles/${id}`, { title })
      .then((result) => {
        dispatch({
          type: actionTypes.STYLE_UPDATED,
          style: result.data
        });
        dispatch(addNotification(NotificationTypes.success, "Стиль сохранен."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.STYLES_UPDATED });
      })
  }
}


export function addStyleAction(title: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.STYLES_UPDATING });

    axios.post('/group-styles', { title })
      .then((result) => {
        dispatch({
          type: actionTypes.STYLE_ADDED,
          style: result.data
        });
        dispatch(addNotification(NotificationTypes.success, "Стиль сохранен."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.STYLES_UPDATED });
      })
  }
}


export function removeStyleAction(id: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.STYLES_UPDATING });

    axios.delete(`/group-styles/${id}`)
      .then((result) => {
        dispatch({
          type: actionTypes.STYLE_REMOVED,
          id: id
        });
        dispatch(addNotification(NotificationTypes.success, "Стиль удален."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.STYLES_UPDATED });
      })
  }
}