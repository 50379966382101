import React from 'react';

import { FaArrowsAlt, FaEdit, FaTrashAlt } from 'react-icons/fa'
import { IStyle } from '../../types/storeTypes';
import ElementLoadAnimation from '../UI/load-animation/ElementAnimation';

type Props = {
  style: IStyle
  notEmpty: boolean
  styleIsRemoving: boolean
  onEditHandler(id: number | null): void
  onRemoveHandler(e: React.MouseEvent): void
}
const StyleTitle: React.FC<Props> = props => {
  const { style, notEmpty, styleIsRemoving, onEditHandler, onRemoveHandler } = props;

  return (
    <tr>
      <th style={{color: "#aaa", fontSize: "0.8rem"}}>{/*<FaArrowsAlt />*/}</th>
      <th colSpan={13}
        onClick={() => onEditHandler(style.id)}
        className={
            "groups-header" +
            (!notEmpty ? " no-groups" : "")
          }>

        <div className="groups-header-title">
          { style.title }
        </div>

        <div className={
          "action-icons" +
          (styleIsRemoving ? " visible" : "")
        }>
          <FaEdit />

          { !styleIsRemoving ?
            <FaTrashAlt onClick={onRemoveHandler} className="remove-icon"/> :
            <ElementLoadAnimation /> }
        </div>

        {/* invisible icon to keep row height */}
        <div className="action-icons-not-visible">
          <FaEdit />
        </div>

      </th>
    </tr>
  );
}

export default StyleTitle;