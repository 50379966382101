import * as actionTypes from '../constants/actionTypes';
import { ICropperState, CropperActions } from '../types/cropperTypes';

const initialState: ICropperState = {
  isOpen: false,
  imageURL: null,
  croppedImage: null
}

export default (state = initialState, action: CropperActions) => {
  switch (action.type) {
    case actionTypes.SET_CROPPING_IMAGE:
      return {
        ...state,
        isOpen: true,
        imageURL: action.image,
        croppedImage: null
      }

    case actionTypes.SET_CROPPED_IMAGE:
      return {
        ...state,
        croppedImage: action.image
      }

    case actionTypes.CLOSE_CROPPER:
      return {
        ...state,
        isOpen: false,
        imageURL: null
      }

    case actionTypes.CLEAR_CROPPER:
      return { ...initialState }

    case actionTypes.CLEAR_DATA:
      return { ...initialState }

    default:
      return state;
  }
}