import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk'
import moment from 'moment';
import { IAppState, IInstructor, IStyle } from '../../types/storeTypes';

import PageHeader from '../template/PageHeader';
import Button from '../UI/form/Button';
import GroupForm from './GroupForm';
import BlockLoadAnimation from '../UI/load-animation/BlockAnimation';
import { addGroupAction, removeGroupAction, updateGroupAction } from '../../store/groupActions';
import { TButton, TButtonIcon, TButtonIconPosition } from '../../types/formTypes';
import { IGroup, IMarks, IGroupTime, Hall } from '../../types/groupsTypes';
import { redirectAction } from '../../store/appActions';

type Props = {
  match: any
  history: any
  group?: IGroup
  instructors: IInstructor[]
  styles: IStyle[]
  basePrice: number
  isStylesUpdating: boolean,
  isGroupsUpdating: boolean,
  isInstructorsUpdating: boolean,
  isPriceUpdating: boolean,
  addGroup(data: IGroup): void,
  updateGroup(id: number, data: IGroup): void,
  removeGroup(id: number): void,
  redirect(path: string): void
}

type State = {
  status: Status
  group: IGroup
}

enum Status { idle, fetching, removing, saving }

class GroupEdit extends React.Component<Props, State> {
  readonly state: State = {
    status: Status.fetching,
    group: {
      id: null,
      numgroup: 0,
      style_id: 0,
      instruktor: 0,
      time: [null, null, null, null, null, null, null],
      number: 0,
      factor: undefined,
      marks: {
        reserve: 0
      },
      comment: ""
    }
  }

  componentDidMount = () => {
    // set id
    const id = this.props.match.params.id;

    if (!id) {
      this.setState({status: Status.idle});
      return null;
    }

    this.setState({group: {...this.state.group, id}});
  }

  componentDidUpdate = () => {
    const { status } = this.state;

    if (status === Status.fetching && !this.isDataLoading() && this.props.group) {
      this.setState({
        group: this.props.group,
        status: Status.idle
      });
    }

    if (status === Status.saving && !this.isDataLoading()) {
      this.setState({status: Status.idle});
    }

    if (status === Status.removing && !this.isDataLoading()) {
      this.props.redirect("/groups");
    }
  }

  componentWillUnmount = () => {
    this.setState({status: Status.fetching});
  }

  isDataLoading = () => {
    const {
      isGroupsUpdating,
      isStylesUpdating,
      isInstructorsUpdating,
      isPriceUpdating
    } = this.props;

    // data is loading
    return (isGroupsUpdating || isStylesUpdating || isInstructorsUpdating || isPriceUpdating);
  }

  saveHandler = () => {
    const { group } = this.state;
    const { style_id, instruktor, factor } = group;

    if (!style_id || !instruktor || !factor) {
      return;
    }

    let number = 0;
    const time = group.time.map(t => {
      const tRe = /\d{2}:\d{2}/;
      if (!t || (!tRe.test(t.start) || !tRe.test(t.end))) {
        return null;
      }

      if (moment(t.start, 'HH:mm').diff((moment(t.end, 'HH:mm'))) >= -15 * 60 * 1000) {
        return null;
      }

      number += 4; // number in month
      return t;
    })

    if (!number) {
      return;
    }

    const groupData = {...group, number, time};

    if (group.id === null) {
      this.props.addGroup(groupData);
    } else {
      this.props.updateGroup(group.id, groupData);
    }

    this.setState({status: Status.saving});
  }

  changeHandler = (e: any) => {
    const target = e.currentTarget;
    const key = target.id;

    if (key.indexOf('time') !== -1) {
      this.updateTime(e);
      return;
    }

    let value: string | number | undefined = target.type !== 'checkbox' ? target.value : target.checked;

    switch (key) {
      case 'style_id':
        value = parseInt(target.value);
        break;
      case 'instruktor':
        value = parseInt(target.value);
        break;
      case 'factor':
        value = target.value > 0 ? parseFloat(target.value) : undefined;
        break;
      case 'marks.reserve':
        value = '';
        break;
      default:
        value = target.value;
        break;
    }

    if (key === 'marks.reserve') {
      const marks: IMarks = {...this.state.group.marks, reserve: target.checked ? 1 : 0};

      this.setState({ group: {...this.state.group, marks} });
    } else {
      this.setState({ group: {...this.state.group, [key]: value} });
    }
  }

  updateTime = (e: any) => {
    const target = e.currentTarget;
    const key = target.id;

    let [ _, i, type]: [string, string, 'start' | 'end' | 'hall'] = key.split('.');
    const ind: number = parseInt(i);

    if (ind === undefined || !type) {
      return;
    }

    let newTime: (IGroupTime | null)[] = this.state.group.time;
    let newdayTime: IGroupTime = newTime[ind] || {start: '', end: '', hall: 'big'};

    if (type === 'hall') {
      newdayTime.hall = target.checked ? "small" : "big";
    } else {
      newdayTime[type] = target.value;
    }

    newTime[ind] = newdayTime;

    this.setState({group: {...this.state.group, time: newTime}});
  }

  removeHandler = () => {
    const { group } = this.state;

    if (!group.id) {
      return;
    }

    const style = this.props.styles.find(s => s.id === group.style_id);
    const instructor = this.props.instructors.find(i => i.id === group.instruktor);

    let msg = !style || !instructor ?
      "Удалить группу?" :
      `Удалить группу "${style.title} - ${instructor.name} ${instructor.surname}"?`;

    const c = window.confirm(msg);

    if (c) {
      this.props.removeGroup(group.id);
      this.setState({status: Status.removing});
    }
  }

  render() {
    const { group, status } = this.state;
    const { instructors, styles, basePrice } = this.props;

    return (
      <React.Fragment>
        <PageHeader title={(!group.id ? "Добавить" : "Редактировать") + " группу"}>
          <Button
            type={TButton.success}
            text="Сохранить"
            icon={ status === Status.saving ? TButtonIcon.loading : TButtonIcon.tick }
            iconPosition={TButtonIconPosition.left}
            clickHandle={this.saveHandler} />

          <Button
            type={TButton.danger}
            text="Удалить"
            icon={ status === Status.removing ? TButtonIcon.loading : TButtonIcon.cross }
            iconPosition={TButtonIconPosition.left}
            clickHandle={this.removeHandler} />

          <Button
            type={TButton.default}
            text="Назад"
            icon={TButtonIcon.undo}
            iconPosition={TButtonIconPosition.left}
            clickHandle={this.props.history.goBack} />

        </PageHeader>

        <section id="main-content" className='card fill-height'>
          {status !== Status.fetching &&
            <GroupForm
              group={group}
              styles={styles}
              instructors={instructors}
              basePrice={basePrice}
              onChange={this.changeHandler}
              onSave={this.saveHandler} />}

          <BlockLoadAnimation dataLoading={status === Status.fetching} />
        </section>

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  isStylesUpdating: state.styles.styles_updating,
  isGroupsUpdating: state.groups.groups_updating,
  isInstructorsUpdating: state.instructors.isUpdating,
  isPriceUpdating: state.groupBasePrice.price_updating
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  addGroup: (data: IGroup) => dispatch(addGroupAction(data)),
  removeGroup: (id: number) => dispatch(removeGroupAction(id)),
  updateGroup: (id: number, data: IGroup) => dispatch(updateGroupAction(id, data)),
  redirect: (path: string) => dispatch(redirectAction(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupEdit);