import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ThunkDispatch } from 'redux-thunk';

import { IInstructorEditData, IInstructor } from '../../types/instructorsTypes';
import { TButton, TButtonIcon, TButtonIconPosition } from "../../types/formTypes";

import PageHeader from '../template/PageHeader';
import InstructorForm from './InstructorForm';
import InstructorPhotoContainer from './InstructorPhotoContainer';
import Button from '../UI/form/Button';
import BlockLoadAnimation from '../UI/load-animation/BlockAnimation';

import './InstructorEditContainer.scss';

import {
  getInstructorAction,
  clearInstructorDataAction,
  updateInstructorAction,
  addInstructorAction,
  removeInstructorAction
} from '../../store/instructorActions';
import { redirectAction } from '../../store/appActions';
import { IAppState } from '../../types/storeTypes';

type Props = {
  match: any
  history: any
  instructor: IInstructor | null
  isUpdating: boolean
  redirect(path: string): void
  getInstructor(id: number): void
  clearInstructorData(): void
  updateInstructor(id: number, data: IInstructorEditData): void
  addInstructor(data: IInstructorEditData): void
  removeInstructor(id: number): void
}

enum Statuses { idle = "idle", fetching = "fetching", removing = "removing", saving = "saving" };

type State = {
  instructor: IInstructor
  newPhoto: Blob | null
  status: Statuses
}


class InstructorsEditContainer extends React.Component<Props, State> {
  readonly state: State = {
    instructor: {
      id: null,
      name: '',
      surname: '',
      phone: '',
      email: '',
      photo: undefined,
      vid: '',
      desc: '',
      archive: false
    },
    status: Statuses.idle,
    newPhoto: null
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      this.setState({
        instructor: {
          ...this.state.instructor,
          id
        }
      });

      this.props.getInstructor(id);
      this.setState({ status: Statuses.fetching });
    }
  }


  componentWillUnmount = () => {
    this.props.clearInstructorData();
    this.setState({ status: Statuses.idle });
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!this.props.instructor) {
      return;
    }

    // load instructor data
    if (prevState.status === Statuses.fetching && prevState.instructor.id !== null) {
      this.setState({ instructor: this.props.instructor, status: Statuses.idle });
    }

    // instructor is saved
    if (this.state.status === Statuses.saving &&
        !this.props.isUpdating &&
        prevProps.isUpdating
    ) {
      this.setState({
        status: Statuses.idle,
        newPhoto: null,
        instructor: this.props.instructor
      });
    }
  }


  handleSubmitForm = (e: any) => {
    e.preventDefault();

    const data: IInstructorEditData = {
      ...this.state.instructor,
      image: this.state.newPhoto
    }

    if (_.isEmpty(data.name)) {
      return;
    }

    if (this.state.instructor.id !== null) {
      this.props.updateInstructor(this.state.instructor.id, data);
    } else {
      this.props.addInstructor(data);
    }

    this.setState({ status: Statuses.saving });
  }


  handleChangeInput = (e: any) => {
    let target = e.target;

    if (!target.name) {
      return;
    }

    const key = target.name;
    let value = target.type !== 'checkbox' ? target.value : target.checked;

    this.setState({ instructor: {
      ...this.state.instructor,
      [key]: value
    }})
  }


  handleRemove = () => {
    const { id, name, surname } = this.state.instructor;

    if (!id) {
      return;
    }

    const fullName = name + (surname && ` ${surname}`);
    const confirm = window.confirm(`Удалить инструктора ${fullName}?`);

    if (!confirm) {
      return;
    }

    this.setState({status: Statuses.removing});

    this.props.removeInstructor(id);
  }


  setPhoto = (image: Blob) => {
    if (!image) return;

    this.setState({
      newPhoto: image,
      instructor: {
        ...this.state.instructor,
        photo: undefined
      }
    });
  }


  removePhoto = () => {
    this.setState({
      newPhoto: null,
      instructor: {
        ...this.state.instructor,
        photo: undefined,
      }
    });
  }


  render() {
    const { id } = this.state.instructor;
    const { status } = this.state;

    return (
      <React.Fragment>
        <PageHeader title={(id === null ? "Добавить" : "Редактировать") + " инструктора"}>
          <Button
            type={TButton.success}
            text="Сохранить"
            icon={ status === Statuses.saving ? TButtonIcon.loading : TButtonIcon.tick }
            iconPosition={TButtonIconPosition.left}
            clickHandle={this.handleSubmitForm} />

          {id && <Button
            type={TButton.danger}
            text="Удалить"
            icon={ status === Statuses.removing ?  TButtonIcon.loading :  TButtonIcon.cross }
            iconPosition={TButtonIconPosition.left}
            clickHandle={this.handleRemove} />
          }

          <Button
            type={TButton.default}
            text="Назад"
            icon={TButtonIcon.undo}
            iconPosition={TButtonIconPosition.left}
            clickHandle={this.props.history.goBack} />

        </PageHeader>

        <section id="main-content" className='card'>

        { this.state.status !== Statuses.fetching &&
          <div id="instructor-edit-page">
            <InstructorForm
              instructor={this.state.instructor}
              handleSubmit={this.handleSubmitForm}
              handleChangeInput={this.handleChangeInput} />

            <InstructorPhotoContainer
              setPhoto={this.setPhoto}
              removePhoto={this.removePhoto}
              photo={this.state.instructor.photo}
              newPhoto={this.state.newPhoto} />

          </div> }

          { this.props.isUpdating && this.state.status === Statuses.fetching &&
            <BlockLoadAnimation dataLoading={true} /> }
        </section>

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  instructor: state.instructors.instructor,
  isUpdating: state.instructors.isUpdating
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  redirect: (path: string) => dispatch(redirectAction(path)),
  getInstructor: (id: number) => dispatch(getInstructorAction(id)),
  clearInstructorData: () => dispatch(clearInstructorDataAction()),
  updateInstructor: (id: number, data: IInstructorEditData) => dispatch(updateInstructorAction(id, data)),
  addInstructor: (data: IInstructorEditData) => dispatch(addInstructorAction(data)),
  removeInstructor: (id: number) => dispatch(removeInstructorAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(InstructorsEditContainer);