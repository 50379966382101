import { IGroupsState, IGroupsActions } from '../types/groupsTypes';
import * as actionTypes from '../constants/actionTypes';

const initialState: IGroupsState = {
  groups: [],
  groups_updating: false
}

export default (state = initialState, action: IGroupsActions) => {
  switch (action.type) {
    case actionTypes.GROUPS_UPDATING:
      return {
        ...state,
        groups_updating: true
      }

    case actionTypes.GROUPS_UPDATED:
      return {
        ...state,
        groups_updating: false
      }

    case actionTypes.GROUPS_RECEIVED:
      return {
        ...state,
        groups: action.groups
      }

    case actionTypes.GROUP_ADDED:
      return {
        ...state,
        groups: state.groups.concat(action.group)
      }

    case actionTypes.GROUP_UPDATED:
      return {
        ...state,
        groups: state.groups.map(g => g.id === action.group.id ? action.group : g)
      }

    case actionTypes.GROUP_REMOVED:
      return {
        ...state,
        groups: state.groups.filter(g => g.id !== action.groupId)
      }

    default:
      return state;
  }
}