import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaSignOutAlt } from "react-icons/fa";
import { IAppState } from '../../types/storeTypes'
import { ThunkDispatch } from 'redux-thunk';

import { logoutAction } from '../../store/authActions';

import './Header.scss';

type Props = {
  isUserLoggedIn: boolean | null
  logoutAction(): void
};

type State = {};

class Header extends React.Component<Props, State> {
  onLogOut = () => {
    this.props.logoutAction();
  }

  render(){
    const { isUserLoggedIn } = this.props;

    return (
      <header id="main-header" className="card">
        <div className="menu-icon"></div>

        <div className="logo">
          <Link to="/">Фитнес Клуб "<b>Атлетизм и грация</b>"</Link>
        </div>

        <div className="headerTitle">
          <nav className="right-nav-icon">
            { isUserLoggedIn &&
              <FaSignOutAlt size={18} onClick={this.onLogOut} /> }
          </nav>
        </div>
      </header>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  isUserLoggedIn: state.auth.userLoggedIn
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  logoutAction: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);