import React, { FormEvent, ChangeEvent } from 'react';

import GroupFormDay from './GroupFormDay';
import SelectWithGroups, { ISelectGroupsValue } from '../UI/form/SelectWithGroups';
import Select, { ISelectValue } from '../UI/form/Select';
import { calculatePrice } from '../../utils/price';

import './GroupForm.scss';
import { IGroup, IInstructor, IStyle } from '../../types/storeTypes';


type Props = {
  group: IGroup,
  styles: IStyle[],
  instructors: IInstructor[]
  basePrice: number
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void
  onSave(): void
}

const GroupForm: React.FC<Props> = (props) => {
  const {
    group,
    instructors,
    styles,
    basePrice,
    onChange,
    onSave
  } = props;

  const instrSelect: ISelectGroupsValue[] = buildInstructorsArray(instructors);
  const stylesSelect: ISelectValue[] = buildStylesArray(styles);

  // console.log(group);

  return (
    <form
      id="group-form"
      className="form-inline"
      onSubmit={(e) => {e.preventDefault(); onSave()}} >

      {/* Style */}
      <div className="form-group">
        <label htmlFor='style_id'>Стиль: </label>

        <Select
          elId="style_id"
          values={stylesSelect}
          selectedValue={group.style_id}
          onChange={onChange} />

      </div>

      {/* Reserve */}
      <div className="form-group">
        <input
          id='marks.reserve'
          className="form-check-input"
          type="checkbox"
          name="smallRoom"
          defaultChecked={(group.marks.reserve && group.marks.reserve == 1) || false}
          onChange={onChange} />

        <label htmlFor='marks.reserve'>Запись в группу: </label>
      </div>

      {/* Instructor */}
      <div className="form-group">
        <label htmlFor='instruktor'>Инструктор: </label>

        <SelectWithGroups
          elId="instruktor"
          values={instrSelect}
          selectedValue={group.instruktor}
          onChange={onChange} />

      </div>

      {/* Factor */}
      <div className="form-group group-factor">
        <label htmlFor='factor'>Коофициент: </label>
        <input
          id='factor'
          type="text"
          className="form-control form-control-sm"
          placeholder='2.00'
          name='factor'
          defaultValue={`${group.factor || ''}`}
          onChange={onChange} />
      </div>

      {/* Comment */}
      <div className="form-group group-comment">
        <label htmlFor='comment'>Комментарий: </label>

        <textarea
          className="form-control"
          id="comment"
          name="comment"
          placeholder="Комментарий"
          rows={3}
          defaultValue={`${group.comment}`}
          onChange={onChange} />

      </div>

      {/** Prices */}
      <div className="group-prices">
        {group.factor && group.factor > 0 &&
          <React.Fragment>
            <div><strong>Разовая:</strong> {calculatePrice(group.factor, basePrice, 1)} руб.</div>
            <div><strong>4 тренировки:</strong> {calculatePrice(group.factor, basePrice, 4)} руб.</div>
            <div><strong>8 тренировок:</strong> {calculatePrice(group.factor, basePrice, 8)} руб.</div>
            <div><strong>12 тренировок:</strong> {calculatePrice(group.factor, basePrice, 12)} руб.</div>
          </React.Fragment>}
      </div>

      <div className="group-schedule">
        <div className="group-schedule-day form-group">
          <div className="small-room-header">Малый зал</div>
        </div>
        <GroupFormDay title="Понедельник" time={group.time[0]} dayNumber={0} onChange={onChange} />
        <GroupFormDay title="Вторник" time={group.time[1]} dayNumber={1} onChange={onChange}  />
        <GroupFormDay title="Среда" time={group.time[2]} dayNumber={2} onChange={onChange}  />
        <GroupFormDay title="Четверг" time={group.time[3]} dayNumber={3} onChange={onChange}  />
        <GroupFormDay title="Пятница" time={group.time[4]} dayNumber={4} onChange={onChange}  />
        <GroupFormDay title="Суббота" time={group.time[5]} dayNumber={5} onChange={onChange}  />
        <GroupFormDay title="Воскресенье" time={group.time[6]} dayNumber={6} onChange={onChange}  />
      </div>

    </form>
  )
}

const buildInstructorsArray = (instructors: IInstructor[]): ISelectGroupsValue[] => {
  let instrs = [...instructors];
  instrs.sort((a, b) => a.name >= b.name ? 1 : -1);
  instrs.sort((a, b) => a.archive && !b.archive ? 1 : -1);

  let values:ISelectGroupsValue[] = [];

  instrs.forEach((i, ind) => {
    if (!i.id) {
      return;
    }

    values.push({
      group: (i.archive ? 'Архив' : 'Активные'),
      value: i.id,
      label: `${i.name} ${i.surname}`
    });
  });

  // add empty
  values.unshift({group:'', value: 0, label: '----------'});

  return values;
}

const buildStylesArray = (stylesProps: IStyle[]): ISelectValue[] => {
  let styles = [...stylesProps];
  styles.sort((a, b) => a.title <= b.title ? -1 : 1);

  const values: ISelectValue[] = styles.map(s => ({value: s.id, label: s.title}));

  // add empty
  values.unshift({value: 0, label: '----------'});

  return values;
}


export default GroupForm;