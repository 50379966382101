import React, { ChangeEvent } from 'react';

type Props = {
  elId: string,
  values: ISelectGroupsValue[]
  selectedValue: number | string
  onChange(e: ChangeEvent <HTMLInputElement | HTMLSelectElement>): void
}

const SelectWithGroups:React.FC<Props> = (props) => {
  const { elId, values, selectedValue, onChange } = props;

  const sortedValues: {[ind: string]: ISelectGroupsValue[]} = {};

  values.forEach(v => {
    if (!sortedValues[v.group]) {
      sortedValues[v.group] = [];
    }

    sortedValues[v.group].push(v);
  });

  return (
    <select
      className="form-control form-control-sm"
      id={elId}
      defaultValue={`${selectedValue}`}
      onChange={onChange} >

      { Object.keys(sortedValues).map(g => {
        return (
          <optgroup key={g} label={g} >

            { sortedValues[g].map(v => {
              return (
                <option
                  key={v.value}
                  value={v.value} >

                  {v.label}

                </option>
              )
            })}

          </optgroup>
        )
      })}

    </select>
  )
}

export interface ISelectGroupsValue {
  group: string
  value: number | string;
  label: string;
}

export default SelectWithGroups;