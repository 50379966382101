import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { ILogin } from '../../types/authTypes';
import { IAppState } from '../../types/storeTypes';

import { loginAction, userLoggedIn, tryToLoginAction } from '../../store/authActions';
import { checkAccessToken } from '../../services/authService';
import LoginForm from './LoginForm';
import './LoginContainer.scss';

enum EStatus { idle = "idle", request = "request" }

type Props = {
  isUserLoggedIn: boolean | null
  pageLoading: boolean
  dataReceiving: boolean
  loginError: boolean
  login(data: ILogin): void
  loggedIn(): void
  tryToLogin(): void
};

type State = {
  name: string
  password: string
  remember: boolean
  status: EStatus
};

class LoginContainer extends React.Component<Props, State> {
  defaultState: State = {
    name: '',
    password: '',
    remember: true,
    status: EStatus.idle
  }

  readonly state: State = this.defaultState;

  componentDidMount = () => {
    this.checkLogin();
  }


  componentDidUpdate = (prevProps: Props) => {
    const {isUserLoggedIn, dataReceiving } = this.props;

    if (!prevProps.isUserLoggedIn && this.props.isUserLoggedIn) {
      this.setState(this.defaultState);
    }

    if (this.state.status === EStatus.request && (prevProps.dataReceiving && !dataReceiving)) {
      this.setState({status: EStatus.idle});
    }
  }


  checkLogin = () => {
    if (checkAccessToken()) {
      this.props.loggedIn();
      return;
    }

    this.props.tryToLogin();
  }


  handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const { name, password, remember } = this.state;

    if (!name || !password) {
      return;
    }

    this.props.login({name, password, remember});
    this.setState({status: EStatus.request});
  }

  handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;

    switch (key) {
      case 'name':
        this.setState({name: e.target.value});
        break;
      case 'password':
        this.setState({password: e.target.value});
        break;
      case 'remember':
        this.setState({remember: e.target.checked});
        break;
    }
  }

  render() {
    const {isUserLoggedIn, pageLoading} = this.props;

    if (isUserLoggedIn || (!isUserLoggedIn && pageLoading)) {
      return null;
    }

    return (
      <section id="login" className="card">
        <LoginForm
          values={this.state}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          isLogging={this.state.status === EStatus.request}
          errorLoggedIn={this.props.loginError} />
      </section>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  isUserLoggedIn: state.auth.userLoggedIn,
  pageLoading: state.app.pageLoading,
  dataReceiving: state.app.dataReceiving,
  loginError: state.auth.loginError
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  login: (data: ILogin) => dispatch(loginAction(data)),
  loggedIn: () => dispatch(userLoggedIn()),
  tryToLogin: () => dispatch(tryToLoginAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);