import * as actionTypes from '../constants/actionTypes';
import { IInstructorsState, InstructorsActions } from '../types/instructorsTypes';

const initialState: IInstructorsState = {
  instructors: [],
  instructor: null,
  instructorSaved: false,
  isUpdating: false
}

export default (state = initialState, action: InstructorsActions) => {
  switch (action.type) {
    case actionTypes.INSTRUCTORS_DATA_IS_UPDATING:
      return {
        ...state,
        isUpdating: true
      }

    case actionTypes.INSTRUCTORS_DATA_UPDATED:
      return {
        ...state,
        isUpdating: false
      }

    case actionTypes.ALL_INSTRUCTORS_RECEIVED:
      return {
        ...state,
        instructors: action.instructors,
        isUpdating: false
      }

    case actionTypes.INSTRUCTOR_RECEIVED:
      return {
        ...state,
        instructor: action.instructor,
        isUpdating: false,
        instructors: state.instructors.map(item => {
          if (item.id !== action.instructor.id) {
            return item
          }

          return action.instructor;
        })
      }

    case actionTypes.INSTRUCTOR_ADDED:
      return {
        ...state,
        instructor: action.instructor,
        instructors: state.instructors.concat(action.instructor),
        isUpdating: false
      }

    case actionTypes.CLEAR_INSTRUCTOR:
      return {
        ...state,
        instructor: null,
        isUpdating: false
      }

    case actionTypes.CLEAR_INSTRUCTORS:
      return {
        ...state,
        instructors: [],
        isUpdating: false
      }

    case actionTypes.INSTRUCTOR_REMOVED:
      return {
        ...state,
        instructors: state.instructors.filter(item => {
          return item.id !== action.id;
        }),
        isUpdating: false
      }

    case actionTypes.CLEAR_DATA:
      return { ...initialState }

    // case actionTypes.INSTRUCTOR_SAVED_STATUS:
    //   return {
    //     ...state,
    //     instructorSaved: action.status
    //   }

    default:
      return state;
  }
}