import React from 'react';

import { IGroup, IInstructor } from '../../types/storeTypes';
import { calculatePrice } from '../../utils/price';

type Props = {
  instructors: IInstructor[]
  groups: IGroup[]
  basePrice: number | null
  openGroupEditHandler(id: number | null): void
};

const GroupRow: React.FC<Props> = props => {
  const { groups, basePrice, instructors, openGroupEditHandler } = props;

  if (groups.length === 0) return null;

  return (
    <React.Fragment>
      { groups.map(group => {
        if (group.id === null) return null;
        const instructor: IInstructor | undefined = instructors.filter(i => i.id === group.instruktor).pop();

        const price1 = group.factor && calculatePrice(group.factor, basePrice, 1);
        const price8 = group.factor && calculatePrice(group.factor, basePrice, (group.number === 4 ? 4 : 8));
        const price12 = group.factor && (group.number === 4 ? null : calculatePrice(group.factor, basePrice, 12));

        return (
          <tr key={group.id} onClick={() => openGroupEditHandler(group.id)}>
            <td></td>
            <td style={{color: "#aaa", fontSize: "0.8rem"}}>{/*<FaArrowsAlt />*/}</td>
            <td>{instructor && `${instructor.name} ${instructor.surname}`}</td>

            { group.time.map((time, ind) => {
              if (time === null) {
                return <td key={ind}></td>
              }

              return <td key={ind} className={`hall-${time.hall}`}>{`${time.start}-${time.end}`}</td>
            })}

            <td>{price1 && `${price1}р`}</td>
            <td>{price8 && `${price8}р`}</td>
            <td>{price12 && `${price12}р`}</td>
            <td>{group.comment}</td>
          </tr>
      )})}
    </React.Fragment>
  );
}

export default GroupRow;