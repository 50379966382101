import React from 'react';
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import Button from '../form/Button';

import './Dropfile.scss';

type Props = {
  buttonText: string
  accept: string
  onDrop(accepted: any, rejected: any): void
}

const Dropfile: React.FC<Props> = props => {
  const {onDrop, buttonText, accept} = props;

  return (
    <Dropzone onDrop={onDrop} accept={accept} disableClick multiple={false}>
      {({getRootProps, getInputProps, isDragActive, open}) => {
        return (
          <div
            {...getRootProps()}
            className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
          >
            <input {...getInputProps()} />
            { isDragActive ?
                <p>Отпускай...</p> :
                <Button text={buttonText} clickHandle={() => open()} />
            }
          </div>
        )
      }}
    </Dropzone>
  )
}

export default Dropfile;