import React from 'react';
import { Link } from 'react-router-dom';
import { TButton, TButtonIcon } from '../../types/formTypes';

import Button from './form/Button';

type Props = {
  link: string
  type: TButton
  text: string
  icon: TButtonIcon
}

const LinkButton: React.FC<Props> = props => {
  const {link, type, text, icon} = props;

  return (
    <Link to={link}>
      <Button type={type} text={text} icon={icon}/>
    </Link>
  )
};

export default LinkButton;