import React from 'react';
import { TButton, TButtonIcon, TButtonIconPosition} from '../../types/formTypes';

import Button from '../UI/form/Button';

type Props = {
  values: any
  handleSubmit(event: any): void
  handleChangeInput(event: any): void
  isLogging: boolean | null
  errorLoggedIn: boolean
};

const LoginForm: React.FC<Props> = props => {
  const { values, handleSubmit, handleChangeInput, isLogging, errorLoggedIn} = props;

  let inputClasses = "form-control form-control-sm";

  if (errorLoggedIn) {
    inputClasses += ' border-error';
  }

  return (
    <form onSubmit={handleSubmit} id="login-form">
      <div className="form-group">
        <input
          className={inputClasses}
          name="name"
          type="text"
          autoFocus
          placeholder="Имя"
          value={values.name}
          onChange={handleChangeInput} />
      </div>

      <div className="form-group">
        <input
          className={inputClasses}
          name="password"
          type="password"
          placeholder="Пароль"
          value={values.password}
          onChange={handleChangeInput} />
      </div>
{/*
      <div className="form-group form-check">
        <input
          className="form-check-input"
          id="remember"
          type="checkbox"
          value={values.remember}
          onChange={handleChangeInput} />

          <label className="form-check-label" htmlFor="remember">
            Запомнить
          </label>
      </div> */}

      <div className="form-group login-submit">
        <Button
          type={TButton.success}
          text='Войти'
          clickHandle={handleSubmit}
          icon={(isLogging && TButtonIcon.loading) || undefined}
          iconPosition={TButtonIconPosition.left} />
      </div>

      <input style={{display: "none"}} type="submit" value="Submit" />
    </form>
  )
}

export default LoginForm;