import React from 'react';
import { connect } from 'react-redux';
import Cropper from 'react-cropper';
import base64js from 'base64-js';
import {
  closeCropperAction, clearCropperAction, setCroppedImageAction
} from '../../../store/cropperActions';
import { IAppState } from '../../../types/storeTypes';
import { TButton, TButtonIcon } from '../../../types/formTypes';

import Button from '../form/Button';

import './ImageCropper.scss';
import { ThunkDispatch } from 'redux-thunk';

type Props = {
  isOpen: boolean
  image: string | null
  clearCropper(): void
  closeCropper(): void
  setCroppedImage(image: any): void
}

type State = {}

class ImageCropperContainer extends React.Component<Props, State> {
  private cropper = React.createRef<any>();

  close = () => {
    this.props.clearCropper();
    this.props.closeCropper();
  }

  crop = async () => {
    const canvas = this.cropper.current.getCroppedCanvas({ fillColor: "#ffffff" });
    const image = await new Promise(resolve => {
      canvas.toBlob((blob: any) => resolve(blob), "image/jpeg", 0.9);
    });

    this.props.setCroppedImage(image);
    this.props.closeCropper();
  }

  // detectMime = () => {
  //   const url = this.props.image;

  //   const ext = url.split(".").pop();

  //   switch (ext) {
  //     case "jpg":
  //       return "image/jpeg";

  //     case "jpeg":
  //       return "image/jpeg";

  //     case "png":
  //       return "image/png";

  //     case "gif":
  //       return "image/gif";

  //     default: return null;
  //   }
  // }

  render() {
    const { isOpen, image } = this.props;

    if (!isOpen || image === null) {
      return null;
    }

    return (
      <div id="image-cropper">
        <div className="inner-container card">

          <Cropper
            className="cropper-wrapper"
            ref={this.cropper}
            src={image}
            autoCropArea={1}
            // aspectRatio={1.78}
            />

          <div className="button-row">
            <Button
              type={TButton.success}
              text="Сохранить"
              icon={TButtonIcon.tick}
              clickHandle={this.crop} />

            <Button
              type={TButton.default}
              text="Отменить"
              icon={TButtonIcon.cross}
              clickHandle={this.close} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  isOpen: state.cropper.isOpen,
  image: state.cropper.imageURL
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  closeCropper: () => dispatch(closeCropperAction()),
  clearCropper: () => dispatch(clearCropperAction()),
  setCroppedImage: (image: any) => dispatch(setCroppedImageAction(image))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropperContainer);