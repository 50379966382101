import * as ActionConstants from '../constants/actionTypes';
import { IClearData } from '../types/appTypes';

export enum NotificationTypes { success = "success", error = "error" }

export interface INotificationsState {
  notifications: INotification[]
}

export interface INotification {
  id: number
  text: string
  messageType: NotificationTypes
}

export interface INotificationAdded {
  type: ActionConstants.ADD_NOTIFICATION
  messageType: NotificationTypes
  text: string
}

export interface INotificationRemoved {
  type: ActionConstants.REMOVE_NOTIFICATION
  id: number
}

export type INotificationActions =
  | INotificationAdded
  | INotificationRemoved
  | IClearData;