import React from 'react';
import { connect } from 'react-redux';

import GroupTablePage from './GroupsTablePage';
import GroupEdit from './GroupEdit';

import { getStylesAction } from '../../store/styleActions';
import { getGroupsAction } from '../../store/groupActions';
import { getInstructorsAction } from '../../store/instructorActions';
import { getGroupBasePriceAction } from '../../store/groupBasePriceActions';
import { redirectAction } from '../../store/appActions';
import { IAppState, IStyle, IGroup, IInstructor } from '../../types/storeTypes';
import { ThunkDispatch } from 'redux-thunk';

type Props = {
  match: any
  history: any
  styles: IStyle[],
  groups: IGroup[],
  instructors: IInstructor[],
  price: number | null,
  getStyles(): void,
  getGroups(): void,
  getInstructors(): void,
  getPrice(): void,
  redirect(path: string): void
}

type State = {}

class Groups extends React.Component<Props, State> {
  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.url !== this.props.match.url) {
      this.getData();
    }
  }

  // get data
  getData = () => {
    const {styles, groups, instructors, price} = this.props;
    const {params} = this.props.match;

    // get styles
    if (styles.length < 2) {
      this.props.getStyles();
    }

    // get instructors
    if (instructors.length < 2) {
      this.props.getInstructors();
    }

    // get base price
    if (price === null) {
      this.props.getPrice();
    }

    // get groups
    if (params.action === 'edit') {
      const groupId = params.id;
      const group = groups.filter(g => {
        return g.id === parseInt(groupId);
      });

      if (group.length !== 1) {
        // TODO: get group
        this.props.getGroups();
      }
    } else if (params.action !== 'add' && groups.length < 2) {
      this.props.getGroups();
    }
  }

  render() {
    const { action, id } = this.props.match.params;
    const { styles, groups, price, instructors } = this.props;

    // add/edit form
    if (action === 'add' || action === 'edit') {
      let group: IGroup | undefined;

      if (action === 'edit') {
        group = this.props.groups.filter(g => g.id === parseInt(id)).pop();
      }

      if (price === null || !instructors || !styles) {
        return null;
      }

      return (
        <GroupEdit
          match={this.props.match}
          history={this.props.history}
          group={group}
          instructors={instructors}
          styles={styles}
          basePrice={price} />
      );
    }

    // group table
    return (
      <GroupTablePage
        styles={styles}
        groups={groups}
        instructors={instructors}
        basePrice={price} />
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  styles: state.styles.styles,
  groups: state.groups.groups,
  instructors: state.instructors.instructors,
  price: state.groupBasePrice.price,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  getStyles: () => dispatch(getStylesAction()),
  getGroups: () => dispatch(getGroupsAction()),
  getInstructors: () => dispatch(getInstructorsAction()),
  getPrice: () => dispatch(getGroupBasePriceAction()),
  redirect: (path: string) => dispatch(redirectAction(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);