import { IStylesState, IStyleActions } from '../types/stylesTypes';
import * as actionTypes from '../constants/actionTypes';

const initialState: IStylesState = {
  styles: [],
  styles_updating: false
}

export default (state = initialState, action: IStyleActions) => {
  switch (action.type) {
    case actionTypes.STYLES_UPDATING:
      return {
        ...state,
        styles_updating: true
      }

    case actionTypes.STYLES_UPDATED:
      return {
        ...state,
        styles_updating: false
      }

    case actionTypes.STYLES_RECEIVED:
      return {
        ...state,
        styles: action.styles
      }

    case actionTypes.STYLE_UPDATED:
      return {
        ...state,
        styles: state.styles.map(s => {
          return s.id === action.style.id ? action.style : s;
        })
      }

    case actionTypes.STYLE_ADDED:
      return {
        ...state,
        styles: state.styles.concat(action.style)
      }

    case actionTypes.STYLE_REMOVED:
      return {
        ...state,
        styles: state.styles.filter(s => s.id !== action.id)
      }

    default:
      return state;
  }
}