import React from 'react';

type Props = {
  notEmpty: boolean
};

const GroupsTableHeader: React.FC<Props> = props => {
  if (!props.notEmpty) {
    return null;
  }

  return (
    <tr>
      <th></th>
      <th></th>
      <th>Инструктор</th>
      <th>Пн.</th>
      <th>Вт.</th>
      <th>Ср.</th>
      <th>Чт.</th>
      <th>Пт.</th>
      <th>Сб.</th>
      <th>Вс.</th>
      <th>Разовая</th>
      <th>4/8 тр.</th>
      <th>12 тр.</th>
      <th>Примечание</th>
    </tr>
  );
}

export default GroupsTableHeader;