import React, { ChangeEvent } from 'react';

type Props = {
  elId: string,
  values: ISelectValue[]
  selectedValue: number | string
  onChange(e: ChangeEvent <HTMLInputElement | HTMLSelectElement>): void
}

const Select:React.FC<Props> = (props) => {
  const { elId, values, selectedValue, onChange} = props;
  return (
    <select
      className="form-control form-control-sm"
      id={elId}
      defaultValue={`${selectedValue}`}
      onChange={onChange} >

      { values.map(v => {
        return (
          <option
            key={v.value || ''}
            value={v.value || ''} >
            {v.label}
          </option>
        )
      })}

    </select>
  )
}

export interface ISelectValue {
  value: number | string | null;
  label: string;
}

export default Select;