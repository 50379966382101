import { combineReducers } from 'redux';

import authReducer from './authReducer';
import appReducer from './appReducer';
import instructorsReducer from './instructorReducer';
import cropperReducer from './cropperReducer';
import notificationReducer from './notificationReducer';
import pageReducer from './pageReducer';
import styleReducer from './styleReducer';
import groupReducer from './groupReducer';
import groupBasePriceReducer from './groupBasePriceReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  instructors: instructorsReducer,
  cropper: cropperReducer,
  notifications: notificationReducer,
  pages: pageReducer,
  styles: styleReducer,
  groups: groupReducer,
  groupBasePrice: groupBasePriceReducer
});

export default rootReducer;