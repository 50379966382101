import React from 'react';
import { FaPlus, FaHistory, FaCheck, FaTimes, FaUndo } from "react-icons/fa";
import ElementLoadAnimation from '../load-animation/ElementAnimation';

import { TButton, TButtonIcon, TButtonIconPosition } from '../../../types/formTypes';

import './Button.scss';

type Props = {
  text: string
  clickHandle?(event: any): void
  type?: TButton
  icon?: TButtonIcon
  iconPosition?: TButtonIconPosition
};

const Button: React.FC<Props> = props => {
  const { type, text, icon, clickHandle, iconPosition } = props;

  const _clickHandle = clickHandle || (() => {return;});
  const _iconPosition = iconPosition || TButtonIconPosition.right;
  const _type = type || TButton.default;

  return (
    <button
      className={"button button-" + _type}
      onClick={_clickHandle} >

      { (icon && _iconPosition === TButtonIconPosition.left) && renderIcon(icon) }

      <span>{text}</span>

      { (icon && _iconPosition === TButtonIconPosition.right) && renderIcon(icon) }
    </button>
  )
};


const iconsMatching: any = {
  add: FaPlus,
  history: FaHistory,
  tick: FaCheck,
  cross: FaTimes,
  undo: FaUndo
}


const renderIcon = (icon: TButtonIcon) => {
  if (icon === 'loading') {
    return <ElementLoadAnimation />
  }

  if (!iconsMatching[icon]) {
    return null;
  }

  const IconComponent = iconsMatching[icon];
  return <IconComponent size='14px' className='button-icon' />;
}


export default Button;