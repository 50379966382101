export const PAGES_UPDATING = 'PAGES_UPDATING';
export type PAGES_UPDATING = typeof PAGES_UPDATING;

export const PAGES_UPDATED = 'PAGES_UPDATED';
export type PAGES_UPDATED = typeof PAGES_UPDATED;

export const PAGES_RECEIVED = 'PAGES_RECEIVED';
export type PAGES_RECEIVED = typeof PAGES_RECEIVED;

export const PAGE_RECEIVED = 'PAGE_RECEIVED';
export type PAGE_RECEIVED = typeof PAGE_RECEIVED;

export const PAGE_ADDED = 'PAGE_ADDED';
export type PAGE_ADDED = typeof PAGE_ADDED;

export const PAGE_UPDATED = 'PAGE_UPDATED';
export type PAGE_UPDATED = typeof PAGE_UPDATED;

export const PAGE_REMOVED = 'PAGE_REMOVED';
export type PAGE_REMOVED = typeof PAGE_REMOVED;

export const CLEAR_PAGE_DATA = 'CLEAR_PAGE_DATA';
export type CLEAR_PAGE_DATA = typeof CLEAR_PAGE_DATA;