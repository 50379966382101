import * as actionTypes from '../constants/actionTypes';

export function openCropperAction(image: string) {
  return { type: actionTypes.SET_CROPPING_IMAGE, image }
};

export function closeCropperAction() {
  return { type: actionTypes.CLOSE_CROPPER }
};

export function setCroppedImageAction(image: any) {
  return { type: actionTypes.SET_CROPPED_IMAGE, image }
};

export function clearCropperAction() {
  return { type: actionTypes.CLEAR_CROPPER }
};
