import React from 'react';
import { connect } from 'react-redux';
import { FaRegTimesCircle } from "react-icons/fa";

import { IInstructorPhotos } from '../../types/instructorsTypes';

import { openCropperAction, clearCropperAction } from '../../store/cropperActions';

import Dropfile from '../UI/dropfile/Dropfile';
import { ThunkDispatch } from 'redux-thunk';

type Props = {
  photo?: IInstructorPhotos
  newPhoto: Blob | null
  setPhoto(image: Blob): void
  removePhoto(): void
  cropperIsOpen: boolean
  croppedImage: Blob | null
  openCropper(image: string): void
  clearCropperData(): void
};

type State = {
  status: Statuses
  image: any | null
  newPhoto: string | null
};

enum Statuses {
  idle = "idle",
  cropping = "cropping"
}

class InstructorPhotoContainer extends React.Component<Props, State> {
  readonly state: State = {
    status: Statuses.idle,
    image: null,
    newPhoto: null
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { status } = this.state;
    const { cropperIsOpen, croppedImage, newPhoto } = this.props;

    // image was cropped
    if (status === Statuses.cropping && !cropperIsOpen) {
      if (croppedImage) {
        this.props.setPhoto(croppedImage);
      }

      this.setState({ status: Statuses.idle });
      this.props.clearCropperData();
    }

    // newPhoto was added or deleted
    if (prevProps.newPhoto !== newPhoto) {
      this.showOrHideNewPhoto();
    }
  }

  onDrop = async (accepted: any, rejected: any) => {
    if (rejected.length > 0 || accepted.length === 0) return;

    const image = await this.convertBlobToString(accepted[0]);

    this.props.openCropper(image);
    this.setState({ status: Statuses.cropping });
  }

  getFilename = (url: any) => {
    if (url === null) return "";

    const urlAr = url.split('/');

    return urlAr.pop();
  }

  showOrHideNewPhoto = async () => {
    const { newPhoto } = this.props;
    const image = !newPhoto ? null : await this.convertBlobToString(newPhoto)

    this.setState({ newPhoto: image });
  }

  convertBlobToString = async (image: Blob): Promise<string> => {
    return new Promise(resolve => {
      const fileReader: FileReader = new FileReader();

      fileReader.onload = (event: Event) => {
        if (typeof(fileReader.result) !== "string") return;

        resolve(fileReader.result);
      };

      fileReader.readAsDataURL(image);
    });
  }

  render() {
    let photo = this.props.photo && this.props.photo.photo;

    if (this.state.newPhoto) {
      photo = this.state.newPhoto;
    }

    return (
      <div className="photo-container">
        <Dropfile
          onDrop={this.onDrop}
          buttonText={ !photo ? "Добавить фото" : "Сменить фото" }
          accept="image/*" />

        { photo &&
          <div className="photo">
            <FaRegTimesCircle onClick={this.props.removePhoto} />
            <img src={photo} alt="" />
          </div> }

        { this.state.image &&
          <img src={this.state.image} />}

      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  cropperIsOpen: state.cropper.isOpen,
  croppedImage: state.cropper.croppedImage
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  openCropper: (image: string) => dispatch(openCropperAction(image)),
  clearCropperData: () => dispatch(clearCropperAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(InstructorPhotoContainer);