import React from 'react';
import { IAppState } from '../../../types/storeTypes';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { INotification, NotificationTypes } from '../../../types/notificationTypes';
import { removeNotification } from '../../../store/notificationActions';
import classNames from 'classnames';

import './Notifications.scss';

const TIMEOUT = 3000;

type Props = {
  notifications: INotification[]
  removeNotification(id: number): void
};

type State = {};

class Notifications extends React.Component<Props, State> {
  componentDidUpdate = (prevProps: Props) => {
    const prevIds = prevProps.notifications.map(item => item.id);

    this.props.notifications.forEach(item => {
      if (prevIds.indexOf(item.id) !== -1) {
        return;
      }

      setTimeout(() => {
        this.props.removeNotification(item.id);
      }, TIMEOUT);
    });
  }

  render() {
    const { notifications } = this.props;

    if (notifications.length === 0) {
      return null;
    }

    return (
      <section id="notifications">

        { notifications.map(item => {
          const className = classNames({
              "notification-item": true,
              "error": item.messageType === NotificationTypes.error
            });

          return (
            <div className={className} key={item.id}>{item.text}</div>
          );
        })}

      </section>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  notifications: state.notifications.notifications
})

const mapsDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  removeNotification: (id: number) => dispatch(removeNotification(id))
});

export default connect(mapStateToProps, mapsDispatchToProps)(Notifications);