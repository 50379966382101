import React from 'react';

import { FaCheck, FaTimes } from 'react-icons/fa';
import ElementLoadAnimation from '../UI/load-animation/ElementAnimation';
import { IStyle } from '../../types/storeTypes';

type Props = {
  style: IStyle
  styleIsSaving: boolean
  onSaveHandler(e: React.MouseEvent | React.FormEvent, id: number | null, title: string): void
  onCancelHandler(): void
}

const StyleTitleEdition: React.FC<Props> = props => {
  const { style, styleIsSaving, onSaveHandler, onCancelHandler } = props;

  let styleTitle = style.title;

  return (
    <tr>
      <th colSpan={14}>
        <div className="groups-header-edit">

          <form
            className="groups-header-edit form"
            onSubmit={(e) => onSaveHandler(e, style.id, styleTitle)}>

            <input
              type="text"
              className="form-control"
              autoFocus
              onChange={(e) => {
                styleTitle = e.target.value;
              }}
              defaultValue={style.title}
              placeholder="Стиль" />

          </form>

          <div className="action-icons">

            { !styleIsSaving ?
              <FaCheck
                onClick={(e) => onSaveHandler(e, style.id, styleTitle)} /> :
              <ElementLoadAnimation />}

            <FaTimes
              onClick={() => onCancelHandler()} />

          </div>

        </div>
      </th>
    </tr>
  );
}

export default StyleTitleEdition;