export const STYLES_UPDATING = 'STYLES_UPDATING';
export type STYLES_UPDATING = typeof STYLES_UPDATING;

export const STYLES_UPDATED = 'STYLES_UPDATED';
export type STYLES_UPDATED = typeof STYLES_UPDATED;

export const STYLES_RECEIVED = 'STYLES_RECEIVED';
export type STYLES_RECEIVED = typeof STYLES_RECEIVED;

export const STYLE_UPDATED = 'STYLE_UPDATED';
export type STYLE_UPDATED = typeof STYLE_UPDATED;

export const STYLE_ADDED = 'STYLE_ADDED';
export type STYLE_ADDED = typeof STYLE_ADDED;

export const STYLE_REMOVED = 'STYLE_REMOVED';
export type STYLE_REMOVED = typeof STYLE_REMOVED;
