export enum TButton {
  default = "default",
  success = "success",
  info = "info",
  warning = "warning",
  danger = "danger"
};

export enum TButtonIcon {
  loading = "loading",
  add = "add",
  history = "history",
  tick = "tick",
  cross = "cross",
  undo = "undo"
};

export enum TButtonIconPosition { left = "left", right = "right" }