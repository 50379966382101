import * as actionTypes from '../constants/actionTypes';
import {
  IGroupBasePriceState,
  IGroupBasePriceActions
} from '../types/groupBasePriceTypes';

const initialState: IGroupBasePriceState = {
  price: null,
  price_updating: false,
}

export default (state = initialState, action: IGroupBasePriceActions) => {
  switch (action.type) {
    case actionTypes.GROUP_BASE_PRICE_UPDATING:
      return {
        ...state,
        price_updating: true
      }

    case actionTypes.GROUP_BASE_PRICE_UPDATED:
      return {
        ...state,
        price_updating: false
      }

    case actionTypes.GROUP_BASE_PRICE_RECEIVED:
      return {
        ...state,
        price: action.price
      }

    case actionTypes.CLEAR_DATA:
      return { ...initialState }

    default:
      return state;
  }
}