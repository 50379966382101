import { logoutAction } from './authActions';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';

export function errorHandle(err: AxiosError) {
  return (dispatch: Dispatch<any>) => {
    console.log(err);

    // auth error
    if (err.response && err.response.status === 401) {
      dispatch(logoutAction());
    }
  }
}