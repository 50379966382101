export const GROUPS_UPDATING = 'GROUPS_UPDATING';
export type GROUPS_UPDATING = typeof GROUPS_UPDATING;

export const GROUPS_UPDATED = 'GROUPS_UPDATED';
export type GROUPS_UPDATED = typeof GROUPS_UPDATED;

export const GROUPS_RECEIVED = 'GROUPS_RECEIVED';
export type GROUPS_RECEIVED = typeof GROUPS_RECEIVED;

export const GROUP_ADDED = 'GROUP_ADDED';
export type GROUP_ADDED = typeof GROUP_ADDED;

export const GROUP_UPDATED = 'GROUP_UPDATED';
export type GROUP_UPDATED = typeof GROUP_UPDATED;

export const GROUP_REMOVED = 'GROUP_REMOVED';
export type GROUP_REMOVED = typeof GROUP_REMOVED;
