import React, { ChangeEvent } from 'react';
import { IGroupTime } from '../../types/storeTypes';

type Props = {
  title: string,
  time: IGroupTime | null
  dayNumber: number
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void
}

const GroupFormDay: React.FC<Props> = (props) => {
  const { time, title, dayNumber, onChange} = props;

  return (
    <div className="group-schedule-day form-group">
      <div>{title}</div>
      <div className="group-time">

        <input
          type="time"
          id={`time.${dayNumber}.start`}
          className="form-control form-control-sm"
          defaultValue={time ? time.start : undefined}
          onChange={onChange} />

        <span className="time-seporator">&ndash;</span>

        <input
          type="time"
          id={`time.${dayNumber}.end`}
          className="form-control form-control-sm"
          defaultValue={time ? time.end : undefined}
          onChange={onChange} />

      </div>

      <input
        id={`time.${dayNumber}.hall`}
        className="form-check-input"
        type="checkbox"
        name="smallRoom"
        defaultChecked={(time ? time.hall === "small" : false)}
        onChange={onChange} />

    </div>
  )
}

export default GroupFormDay;