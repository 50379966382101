import axios from 'axios';
import { checkAccessToken, getAccessToken, renewAccessToken, saveAccessToken } from '../services/authService';
import { AxiosError } from 'axios';
import { IAccessToken } from '../types/authTypes';

if (!axios.defaults.baseURL) {
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.baseURL = process.env.REACT_APP_HOST;
}

axios.interceptors.request.use(async function (config) {
  if (config.url === '/login' || config.url === '/auth/token') {
    return config;
  }

  try {
    const authTokenIsValid = await checkAccessToken();

    if (!authTokenIsValid) {
      const accessToken:IAccessToken = await renewAccessToken();
      saveAccessToken(accessToken);
    }
  } catch (err) {
    const authError: AxiosError = {
      name: 'Auth Error',
      config,
      code: '401',
      message: err.message
    };

    return Promise.reject(authError);
  }

  config.headers['Authorization'] = `Bearer ${getAccessToken().token}`;

  return config;
});

export default axios;
