import React from 'react';

import './PageHeader.scss';

type Props = {
  title: string
}

const PageHeader: React.FC<Props> = props => {
  const {title, children} = props;

  return (
    <header id="page-header" className='card'>
      <h1>{title}</h1>
      { children && <div className="page-header-buttons">{children}</div> }
    </header>
  );
};

export default PageHeader;