/**
 * Calculates price
 *
 * 12: round(bp * factor * 0.85) * 12
 * 8: round(bp * factor * 0.925) * 8
 * 4: round(bp * factor) * 4
 * 1: round(round(bp * factor) * 1.1)
 *
 * @param number factor
 * @param number basePrice
 * @param number 1 | 4 | 8 | 12 amount
 * @return number price
 */
export const calculatePrice = (
  factor: number,
  basePrice: number | null,
  amount: 1 | 4 | 8 | 12
): number | null => {
  basePrice = basePrice || 0;
  const amountFactors = {
    1: 1.1,
    4: 1,
    8: 0.925,
    12: 0.85
  };

  let startPrice = 0;
  let finalPrice = 0;

  if (amount === 1) {
    startPrice = (Math.round(basePrice * factor * 10) / 10) * amountFactors[amount];
  } else {
    startPrice = Math.round(basePrice * factor * amountFactors[amount] * 10) / 10;
  }

  finalPrice = Math.round(startPrice * amount * 10) / 10;

  return finalPrice;
}