import axios from '../config/axios';
import { Dispatch } from 'redux';
import * as actionTypes from '../constants/actionTypes';
import { errorHandle } from './errorHandle';
import { IGroup } from '../types/storeTypes';
import { addNotification } from './notificationActions';
import { NotificationTypes } from '../types/notificationTypes';

export function getGroupsAction() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.GROUPS_UPDATING });

    axios.get('/groups')
      .then((result) => {
        dispatch({
          type: actionTypes.GROUPS_RECEIVED,
          groups: result.data
        });
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.GROUPS_UPDATED });
      })
  }
}

export function addGroupAction(data: IGroup) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.GROUPS_UPDATING });

    axios.post(`/groups`, data)
      .then((result) => {
        dispatch({ type: actionTypes.GROUP_ADDED, group: result.data });
        dispatch(addNotification(NotificationTypes.success, "Группа создана."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.GROUPS_UPDATED });
      });
  }
}

export function updateGroupAction(id: number, data: IGroup) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.GROUPS_UPDATING });

    axios.patch(`/groups/${id}`, data)
      .then((result) => {
        dispatch({ type: actionTypes.GROUP_UPDATED, group: result.data });
        dispatch(addNotification(NotificationTypes.success, "Группа обновленв."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.GROUPS_UPDATED });
      })
  }
}

export function removeGroupAction(id: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.GROUPS_UPDATING });

    axios.delete(`/groups/${id}`)
      .then((result) => {
        dispatch({ type: actionTypes.GROUP_REMOVED, groupId: id });
        dispatch(addNotification(NotificationTypes.success, "Группа удалена."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.GROUPS_UPDATED });
      })
  }
}
