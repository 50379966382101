import React from 'react';

import Header from './components/template/Header';
import Footer from './components/template/Footer';
import Login from './components/login/LoginContainer';
import MainMenu from './components/template/MainMenu';
import Router from './components/Router';
import PageAnimation from './components/UI/load-animation/PageAnimation';
import ImageCropper from './components/UI/image-cropper/ImageCropper';
import Notifications from './components/UI/notifications/Notifications';

import './App.scss';

type Props = {};

const App: React.FC<Props> = props => {
  return (
    <div className="App container px-0">
      <Header />

      <Login />

      <MainMenu/>
      <Router />

      <Footer />
      <Notifications />

      <ImageCropper />
      <PageAnimation />
    </div>
  );
};

export default App;