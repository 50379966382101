import * as actionTypes from '../constants/actionTypes';
import { IAuthState, AuthActions } from '../types/authTypes';

const initialState: IAuthState = {
  userLoggedIn: null,
  loginError: false
}

export default (state = initialState, action: AuthActions): IAuthState => {
  switch (action.type) {
    case actionTypes.USER_LOGGED_IN:
      return { ...state, userLoggedIn: true }

    case actionTypes.USER_LOGGED_OUT:
      return { ...state, userLoggedIn: false }

    case actionTypes.LOGIN_ERROR:
      return { ...state, loginError: true }

    case actionTypes.CLEAR_DATA:
      return { ...initialState }

    default:
      return state;
  }
}