import axios from '../config/axios';
import { Dispatch } from 'redux';
import * as actionTypes from '../constants/actionTypes';
import { errorHandle } from './errorHandle';
import { IPage } from '../types/pagesTypes';
import { addNotification } from './notificationActions';
import { NotificationTypes } from '../types/notificationTypes';

export function getPagesAction() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.PAGES_UPDATING });

    axios.get('/pages')
      .then((result) => {
        dispatch({
          type: actionTypes.PAGES_RECEIVED,
          pages: result.data
        });
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.PAGES_UPDATED });
      })
  }
}

export function getPageAction(id: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.PAGES_UPDATING });

    axios.get(`/pages/${id}`)
      .then((result) => {
        dispatch({
          type: actionTypes.PAGE_RECEIVED,
          page: result.data
        });
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.PAGES_UPDATED });
      })
  }
}


export function addPageAction(data: IPage) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.PAGES_UPDATING });

    axios.post('/pages', data)
      .then((result) => {
        dispatch({ type: actionTypes.PAGE_RECEIVED, page: result.data });
        dispatch({ type: actionTypes.PAGE_ADDED, page: result.data });
        dispatch(addNotification(NotificationTypes.success, "Страница сохранена."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.PAGES_UPDATED });
      })
  }
}


export function updatePageAction(id: number, data: IPage) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.PAGES_UPDATING });

    axios.patch(`/pages/${id}`, data)
      .then((result) => {
        dispatch({ type: actionTypes.PAGE_RECEIVED, page: result.data });
        dispatch({ type: actionTypes.PAGE_UPDATED, page: result.data });
        dispatch(addNotification(NotificationTypes.success, "Страница сохранена."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.PAGES_UPDATED });
      })
  }
}

export function removePageAction(id: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.PAGES_UPDATING });

    axios.delete(`/pages/${id}`)
      .then((result) => {
        dispatch({ type: actionTypes.PAGE_REMOVED, page_id: id });
        dispatch(addNotification(NotificationTypes.success, "Страница удалена."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.PAGES_UPDATED });
      })
  }
}

export function clearPageDataAction() {
  return {type: actionTypes.CLEAR_PAGE_DATA }
}