import axios from '../config/axios';
import { Dispatch } from 'redux';
import _ from 'lodash';
import * as actionTypes from '../constants/actionTypes';
import { IAccessToken, ILogin } from '../types/authTypes';
import {
  saveAccessToken,
  saveRefreshToken,
  deleteAccessToken,
  deleteRefreshToken,
  getRefreshToken,
  renewAccessToken
} from '../services/authService';
import { AxiosError } from 'axios';


export function loginAction(data: ILogin) {
  return (dispatch: Dispatch<any>) => {
    dispatch({type: actionTypes.DATA_RECEIVING});

    return (
      axios.post('/login', data)
        .then((res) => {
          const refreshToken = res.data.refreshToken;
          const accessToken = res.data.accessToken;

          saveRefreshToken(refreshToken);
          saveAccessToken(accessToken);

          dispatch(userLoggedIn());
        }).catch((err) => {
          dispatch({ type: actionTypes.LOGIN_ERROR });
        }).then(() => {
          dispatch({type: actionTypes.DATA_RECEIVED});
        })
    );
  }
}


export const userLoggedIn = () => {
  return { type: actionTypes.USER_LOGGED_IN }
}


export function logoutAction() {
  deleteAccessToken();
  deleteRefreshToken();

  return (dispatch: Dispatch<any>) => {
    dispatch(userLoggedOut());
    dispatch({ type: actionTypes.CLEAR_DATA });
  }
}


const userLoggedOut = () => {
  return { type: actionTypes.USER_LOGGED_OUT }
}


export const tryToLoginAction = () => {
  return (dispatch: Dispatch<any>) => {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      return dispatch(logoutAction());
    }

    dispatch({type: actionTypes.PAGE_LOADING});

    return renewAccessToken()
      .then((accessToken: IAccessToken) => {
        saveAccessToken(accessToken);

        dispatch(userLoggedIn());
      }).catch((err: AxiosError | Error) => {
        dispatch(logoutAction());
      }).then(() => {
        dispatch({type: actionTypes.PAGE_LOADED});
      });
  }
}


// export function checkAuthOnLoadApp() {
//   return async (dispatch: Dispatch<any>) => {
//     dispatch({type: actionTypes.PAGE_LOADING});

//     checkAuth().then(() => {
//       dispatch({type: actionTypes.PAGE_LOADED});
//       dispatch(userLoggedIn());
//     }).catch(() => {
//       dispatch({type: actionTypes.PAGE_LOADED});
//       dispatch(userLoggedOut());
//     });
//   }
// }

// export async function checkAuthForRequest() {
//   return checkAuth();
// }

// const checkAuth = async () => {
//   const accessToken = getAccessToken();

//   if (validateAccessToken(accessToken)) {
//     return true;
//   }

//   if (_.isNull(getRefreshToken())) {
//     throw new Error('Refresh token not found.');
//   }

//   await renewAccessToken();

//   return true;
// }