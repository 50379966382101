import React from 'react';
import { IPage } from '../../types/pagesTypes';

import './PageForm.scss';

type Props = {
  page: IPage
  handleSubmit(e: any): void
  handleChangeInput(e: any): void
}

const PageForm: React.FC<Props> = props => {
  const { handleSubmit, handleChangeInput, page } = props;
  const url = `${process.env.REACT_APP_SITE_HOST}/${page.page_slug}`;

  if (!page) return null;

  return (
    <form onSubmit={handleSubmit} className="form" id="page-form">
      <div className="form-group">
        <textarea
          className="form-control form-control-sm page-content"
          id="page_content"
          name="page_content"
          placeholder="Текст"
          value={page.page_content}
          onChange={handleChangeInput} />
      </div>

      <div className="form-group">
        <label htmlFor='page_title'>Заголовок: </label>
        <input
          id='page_title'
          type="text"
          className="form-control form-control-sm"
          placeholder='Заголовок'
          name='page_title'
          value={page.page_title}
          onChange={handleChangeInput} />

        {page.page_slug &&
          <React.Fragment>
            <label htmlFor='page_url'>URL: </label>
            <div>
              <a href={url} target="_blank">{url}</a>
            </div>
          </React.Fragment>
        }

      </div>

    </form>
  );
}

export default PageForm;