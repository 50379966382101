import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../../types/storeTypes'

import {
  FaUserFriends,
  FaRegFileAlt,
  FaChild
} from 'react-icons/fa';

import './MainMenu.scss';

type Props = {
  isUserLoggedIn: boolean | null
};

type State = {};

class MainMenu extends React.Component<Props, State> {
  render() {
    if (!this.props.isUserLoggedIn) {
      return null;
    }

    return (
      <aside className='card'>
        <nav className="main-menu">
          <Link to={'/instructors'}><span className='menu-icon'><FaUserFriends /></span>Инструкторы</Link>
          <Link to={'/pages'}><span className='menu-icon'><FaRegFileAlt /></span>Страницы</Link>
          <Link to={'/groups'}><span className='menu-icon'><FaChild /></span>Групповые</Link>
        </nav>
      </aside>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  isUserLoggedIn: state.auth.userLoggedIn,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);