import React from 'react';

import './Footer.scss';

type Props = {};

const Footer: React.FC<Props> = props => {
  return (
    <footer>
      <div>
        <span>
          <strong>© <a href="https://fitness-ag.by">Фитнес-клуб "Атлетизм и грация"</a></strong> {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  )
}

export default Footer;