import * as actionConstants from '../constants/actionTypes';
import { INotificationsState, INotificationActions } from '../types/notificationTypes';

const initialState: INotificationsState = {
  notifications: [],
}

export default (state = initialState, action: INotificationActions) => {
  switch (action.type) {
    case actionConstants.ADD_NOTIFICATION:
      const id = new Date().getTime();

      return {
        ...state,
        notifications: state.notifications.concat({
          id,
          messageType: action.messageType,
          text: action.text
        })
      }

    case actionConstants.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(item => {
          return item.id !== action.id
        })
      }

    case actionConstants.CLEAR_DATA:
      return { ...initialState }

    default:
      return state;
  }
}