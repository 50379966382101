import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IAppState } from '../types/storeTypes';

import { resetRedirectAction } from '../store/appActions';

import InstructorsContainer from './instructors/InstructorsContainer';
import InstructorEditContainer from './instructors/InstructorEditContainer';

import Pages from './pages/Pages';
import PageEditContainer from './pages/PageEditContainer';

import Groups from './groups/Groups';
import GroupEdit from './groups/GroupEdit';

import Build from './Build';

import './Router.scss';

type PathParams = {
  param1: string
}

type Props = RouteComponentProps<PathParams> & {
  isUserLoggedIn: boolean | null
  redirectPath: string | null
  resetRedirect(): void
};

type State = {};

class Router extends React.Component<Props, State> {
  componentDidUpdate = (prevProps: Props) => {
    if (this.props.redirectPath && prevProps.redirectPath === null) {
      this.props.resetRedirect();
    }
  }

  render() {
    if (!this.props.isUserLoggedIn) {
      return null;
    }

    if (this.props.redirectPath) {
      return <Redirect push to={this.props.redirectPath} />
    }

    return (
      <Switch>
        <Redirect exact from='/' to='/instructors'/>

        {/* instructors */}
        <Route exact path="/instructors" component={() => <InstructorsContainer isArchive={false} />} />
        <Route path="/instructors/archive" component={() => <InstructorsContainer isArchive={true} />} />
        <Route path="/instructors/:id/edit" component={InstructorEditContainer} />
        <Route path="/instructors/add" component={InstructorEditContainer} />

        {/* pages */}
        <Route exact path="/pages" component={Pages} />
        <Route path="/pages/:id/edit" component={PageEditContainer} />
        <Route path="/pages/add" component={PageEditContainer} />

        {/* groups */}
        <Route path="/groups/:action?/:id?" component={Groups} />

        {/* build */}
        <Route path="/b" component={Build} />
      </Switch>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  isUserLoggedIn: state.auth.userLoggedIn,
  redirectPath: state.app.redirectPath
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  resetRedirect: () => dispatch(resetRedirectAction())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Router));
