import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk'

import PageHeader from '../template/PageHeader';
import LinkButton from '../UI/LinkButton';
import Button from '../UI/form/Button';
import { TButton, TButtonIcon } from '../../types/formTypes';
import BlockLoadAnimation from '../UI/load-animation/BlockAnimation';

import { updateStyleAction } from '../../store/styleActions';
import { redirectAction } from '../../store/appActions';
import { IAppState, IStyle, IGroup, IInstructor } from '../../types/storeTypes';

import Style from './Style';
import GroupsTableHeader from './GroupsTableHeader';
import GroupRow from './GroupRow';

import './Groups.scss';

type Props = {
  styles: IStyle[],
  groups: IGroup[],
  instructors: IInstructor[],
  basePrice: number | null,
  isStylesUpdating: boolean,
  isGroupsUpdating: boolean,
  isInstructorsUpdating: boolean,
  isPriceUpdating: boolean,
  redirect(path: string): void
};

type State = {
  status: Status
  addNewStyle: boolean
};

enum Status { idle, fetching };

class GroupsTablePage extends React.Component<Props, State> {
  readonly state: State = {
    status: Status.fetching,
    addNewStyle: false
  }

  componentDidMount = () => {
    if (!this.isDataLoading()) {
      this.setState({status: Status.idle});
    }
  }

  componentDidUpdate = (prevProps: Props) => {
      const { addNewStyle, status } = this.state;
      const { styles: newStyles } = this.props;
      const { styles: oldStyles } = prevProps;

      // data is loaded
      if (status === Status.fetching && !this.isDataLoading()) {
        this.setState({status: Status.idle});
      }

      // save/edit style
      if (addNewStyle && newStyles.length > oldStyles.length) {
        this.setState({addNewStyle: false});
      }
  }

  componentWillUnmount = () => {
    this.setState({status: Status.fetching});
  }

  isDataLoading = () => {
    const {
      isGroupsUpdating,
      isStylesUpdating,
      isInstructorsUpdating,
      isPriceUpdating
    } = this.props;

    // data is loading
    return (isGroupsUpdating || isStylesUpdating || isInstructorsUpdating || isPriceUpdating);
  }

  addNewStyleClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!this.state.addNewStyle) {
      this.setState({addNewStyle: true});
    }
  }

  newStyleAddedHandler = () => {
    this.setState({addNewStyle: false});
  }

  openGroupEditHandler = (id: number | null) => {
    if (!id) {
      return;
    }

    this.props.redirect(`/groups/edit/${id}`);
  }

  render() {
    const { status } = this.state;
    let styles = [...this.props.styles];

    // add new style
    if (this.state.addNewStyle) {
      styles.push({
        id: null,
        title: ""
      });
    }

    return (
      <React.Fragment>
        <PageHeader title="Групповые занятия">
          <LinkButton
            link="/groups/add"
            type={TButton.success}
            text="Добавить группу"
            icon={TButtonIcon.add} />

          <span>
            <Button
              text="Добавить стиль"
              clickHandle={this.addNewStyleClickHandler}
              type={TButton.success}
              icon={TButtonIcon.add} />
          </span>
        </PageHeader>

        <section id="main-content" className="card">
          {status !== Status.fetching &&
            (styles.map(style => {
              const styleGroups = this.props.groups.filter((group => (
                group.style_id === style.id
              )));

              return (
                <table className="table table-sm table-hover groups" key={style.id || 0}>
                  <thead>

                    <Style
                      style={style}
                      countGroups={styleGroups.length}
                      newStyleAdded={this.newStyleAddedHandler} />

                    <GroupsTableHeader
                      notEmpty={styleGroups.length > 0} />

                  </thead>

                  <tbody>

                    <GroupRow
                      instructors={this.props.instructors}
                      basePrice={this.props.basePrice}
                      groups={styleGroups}
                      openGroupEditHandler={this.openGroupEditHandler} />

                  </tbody>
                </table>
              )
            }))}

          <BlockLoadAnimation dataLoading={status === Status.fetching} />
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  isGroupsUpdating: state.groups.groups_updating,
  isInstructorsUpdating: state.instructors.isUpdating,
  isStylesUpdating: state.styles.styles_updating,
  isPriceUpdating: state.groupBasePrice.price_updating
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  redirect: (path: string) => dispatch(redirectAction(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTablePage);
