// App
export const CLEAR_DATA = 'CLEAR_DATA';
export type CLEAR_DATA = typeof CLEAR_DATA;

export const SET_REDIRECT = 'SET_REDIRECT';
export type SET_REDIRECT = typeof SET_REDIRECT;

export const RESET_REDIRECT = 'RESET_REDIRECT';
export type RESET_REDIRECT = typeof RESET_REDIRECT;


// Fetching
export const PAGE_LOADING = 'PAGE_LOADING';
export type PAGE_LOADING = typeof PAGE_LOADING;

export const PAGE_LOADED = 'PAGE_LOADED';
export type PAGE_LOADED = typeof PAGE_LOADED;

export const DATA_RECEIVING = 'DATA_RECEIVING';
export type DATA_RECEIVING = typeof DATA_RECEIVING;

export const DATA_RECEIVED = 'DATA_RECEIVED';
export type DATA_RECEIVED = typeof DATA_RECEIVED;


// instructors
export const INSTRUCTORS_DATA_IS_UPDATING = 'INSTRUCTORS_DATA_IS_UPDATING';
export type INSTRUCTORS_DATA_IS_UPDATING = typeof INSTRUCTORS_DATA_IS_UPDATING;

export const INSTRUCTORS_DATA_UPDATED = 'INSTRUCTORS_DATA_UPDATED';
export type INSTRUCTORS_DATA_UPDATED = typeof INSTRUCTORS_DATA_UPDATED;

export const ALL_INSTRUCTORS_REQUEST = 'ALL_INSTRUCTORS_REQUEST';
export type ALL_INSTRUCTORS_REQUEST = typeof ALL_INSTRUCTORS_REQUEST;

export const ALL_INSTRUCTORS_RECEIVED = 'ALL_INSTRUCTORS_RECEIVED';
export type ALL_INSTRUCTORS_RECEIVED = typeof ALL_INSTRUCTORS_RECEIVED;

export const INSTRUCTOR_RECEIVED = 'INSTRUCTOR_RECEIVED';
export type INSTRUCTOR_RECEIVED = typeof INSTRUCTOR_RECEIVED;

export const CLEAR_INSTRUCTORS = 'CLEAR_INSTRUCTORS';
export type CLEAR_INSTRUCTORS = typeof CLEAR_INSTRUCTORS;

export const CLEAR_INSTRUCTOR = 'CLEAR_INSTRUCTOR';
export type CLEAR_INSTRUCTOR = typeof CLEAR_INSTRUCTOR;

export const INSTRUCTOR_REMOVED = 'INSTRUCTOR_REMOVED';
export type INSTRUCTOR_REMOVED = typeof INSTRUCTOR_REMOVED;

export const INSTRUCTOR_ADDED = 'INSTRUCTOR_ADDED';
export type INSTRUCTOR_ADDED = typeof INSTRUCTOR_ADDED;


// auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export type LOGIN_REQUEST = typeof LOGIN_REQUEST;

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export type USER_LOGGED_IN = typeof USER_LOGGED_IN;

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export type USER_LOGGED_OUT = typeof USER_LOGGED_OUT;

export const LOGIN_ERROR = 'LOGIN_ERROR';
export type LOGIN_ERROR = typeof LOGIN_ERROR;


// cropper
export const SET_CROPPING_IMAGE = 'SET_CROPPING_IMAGE';
export type SET_CROPPING_IMAGE = typeof SET_CROPPING_IMAGE;

export const SET_CROPPED_IMAGE = 'SET_CROPPED_IMAGE';
export type SET_CROPPED_IMAGE = typeof SET_CROPPED_IMAGE;

export const CLOSE_CROPPER = 'CLOSE_CROPPER';
export type CLOSE_CROPPER = typeof CLOSE_CROPPER;

export const CLEAR_CROPPER = 'CLEAR_CROPPER';
export type CLEAR_CROPPER = typeof CLEAR_CROPPER;

// upload
export const SET_UPLOADED_FILE_URL = 'SET_UPLOADED_FILE_URL';
export const RESET_UPLOADED_FILE = 'RESET_UPLOADED_FILE';

export * from './notificationTypes';
export * from './pagesTypes';
export * from './stylesTypes';
export * from './groupsTypes';
export * from './groupBasePriceTypes';
