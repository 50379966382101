import React from 'react';
import { IInstructor } from '../../types/instructorsTypes';

import './InstructorCard.scss';

type Props = {
  instr: IInstructor
  handleEditClick(id: number | null): void
};

const InstructorCard: React.FC<Props> = props => {
  const {instr, handleEditClick} = props;

  const photo: string | null = instr.photo && instr.photo["330"] || null;

  return (
    <div className="instructor-card card" onClick={() => handleEditClick(instr.id)} >
      <div className="name">{instr.name} {instr.surname}</div>

      <div className="photo">
        { photo &&
          <img src={photo} alt="" /> }
      </div>
    </div>
  )
}

export default InstructorCard;