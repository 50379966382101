import React, { FormEvent, ChangeEvent } from 'react';
import { IInstructor } from '../../types/instructorsTypes';

import './InstructorForm.scss';

type Props = {
  instructor: IInstructor
  handleSubmit(e: FormEvent<HTMLFormElement>): void
  handleChangeInput(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
}

const InstructorEditForm: React.FC<Props> = props => {
  const { instructor, handleSubmit, handleChangeInput } = props;

  return (
    <form onSubmit={handleSubmit} id="instructor-form" className="form-inline">
      <div className="form-group">
        <label htmlFor='name'>Имя: </label>
        <input
          id='name'
          type="text"
          className="form-control form-control-sm"
          placeholder='Имя'
          name='name'
          value={instructor.name}
          onChange={handleChangeInput} />
      </div>

      <div className="form-group instructor-form-archive">
        <label htmlFor='lastName'>Фамилия: </label>
        <input
          id='lastName'
          type="text"
          className="form-control form-control-sm"
          placeholder='Фамилия'
          name='surname'
          value={instructor.surname}
          onChange={handleChangeInput} />
      </div>

      <div className="form-group">
        <input
          id='archive'
          className="form-check-input"
          type="checkbox"
          name='archive'
          checked={instructor.archive}
          onChange={handleChangeInput} />
        <label htmlFor='archive'>В архив</label>
      </div>

      <div className="form-group">
        <label htmlFor='phone'>Телефон: </label>
        <input
          id='phone'
          type="text"
          className="form-control form-control-sm"
          placeholder='Телефон'
          name='phone'
          value={instructor.phone}
          onChange={handleChangeInput} />
      </div>

      <div className="form-group">
        <label htmlFor='email'>Email: </label>
        <input
          id='email'
          type="text"
          className="form-control form-control-sm"
          placeholder='Email'
          name='email'
          value={instructor.email}
          onChange={handleChangeInput} />
      </div>

      <div className="form-group" style={{gridColumn: "1 / 4"}}>
        <label htmlFor='vid'>Краткое описание (Виды): </label>
        <input
          id='vid'
          type="text"
          className="form-control form-control-sm"
          placeholder='Инструктор Женского фитнеса'
          name='vid'
          value={instructor.vid}
          onChange={handleChangeInput} />
      </div>

      <div className="form-group" style={{gridColumn: "1 / 4"}}>
        <label htmlFor='desc'>Описание: </label>
        <textarea
          className="form-control"
          id="desc"
          name="desc"
          placeholder="Описание"
          rows={10}
          value={instructor.desc}
          onChange={handleChangeInput} />
      </div>

      <input style={{display: "none"}} type="submit" value="Submit" />

    </form>
  );
}

export default InstructorEditForm;