import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../types/storeTypes';

import './PageAnimation.scss';

type Props = {
  pageLoading: boolean
};

type State = {};

class PageAnimation extends React.Component<Props, State> {
  render() {
    if (!this.props.pageLoading) {
      return null;
    }

    return (
      <div id='load-animation'>
        <div className="load-animation-gif"></div>
      </div>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  pageLoading: state.app.pageLoading
});

export default connect(mapStateToProps)(PageAnimation);