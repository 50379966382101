import * as actionTypes from '../constants/actionTypes';
import { ICommonState, CommonActions } from '../types/appTypes';

const initialState: ICommonState = {
  pageLoading: false,
  dataReceiving: false,
  redirectPath: null
}

export default (state = initialState, action: CommonActions): ICommonState => {
  switch (action.type) {
    case actionTypes.PAGE_LOADING:
      return { ...state, pageLoading: true }

    case actionTypes.PAGE_LOADED:
      return { ...state, pageLoading: false }

    case actionTypes.DATA_RECEIVING:
      return { ...state, dataReceiving: true }

    case actionTypes.DATA_RECEIVED:
      return { ...state, dataReceiving: false }

    case actionTypes.SET_REDIRECT:
      return { ...state, redirectPath: action.path }

    case actionTypes.RESET_REDIRECT:
      return { ...state, redirectPath: null }

    case actionTypes.CLEAR_DATA:
      return { ...initialState }

    default:
      return state;
  }
}