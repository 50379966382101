import { NotificationTypes } from '../types/notificationTypes';
import * as ActionConstants from '../constants/actionTypes';

export const addNotification = (messageType: NotificationTypes, text: string) => ({
  type: ActionConstants.ADD_NOTIFICATION,
  messageType,
  text
});

export const removeNotification = (id: number) => ({
  type: ActionConstants.REMOVE_NOTIFICATION,
  id
});
