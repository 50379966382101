import React from 'react';
import { connect } from 'react-redux';
import { TButton, TButtonIcon } from '../../types/formTypes';

import './InstructorsContainer.scss';

import { getInstructorsAction } from '../../store/instructorActions';
import { redirectAction } from '../../store/appActions';
import { IInstructor } from '../../types/instructorsTypes';
import { IAppState } from '../../types/storeTypes';
import { ThunkDispatch } from 'redux-thunk';

import PageHeader from '../template/PageHeader';
import InstructorCard from './InstructorCard';
import LinkButton from '../UI/LinkButton';
import BlockLoadAnimation from '../UI/load-animation/BlockAnimation';

type Props = {
  isArchive: boolean
  instructors: IInstructor[]
  isUpdating: boolean
  getInstructors(): void
  redirect(path: string): void
};

type State = {};

class InstructorsContainer extends React.Component<Props, State> {
  componentDidMount = () => {
    if (this.props.instructors.length <= 1) {
      this.props.getInstructors();
    }
  }

  handleEditClick = (instrId: number | null) => {
    if (instrId === null) {
      return;
    }

    this.props.redirect(`/instructors/${instrId}/edit`);
  }

  render() {
    const switchArchiveButtonText = this.props.isArchive ? "Инструкторы" : "Архив";
    const switchArchiveButtonURL = this.props.isArchive ? "/instructors" : "/instructors/archive";

    return (
      <React.Fragment>
        <PageHeader title={"Инструкторы" + (this.props.isArchive ? " (Архив)" : "")}>
          <LinkButton
            link="/instructors/add"
            type={TButton.success}
            text="Добавить"
            icon={TButtonIcon.add} />

          <LinkButton
            link={switchArchiveButtonURL}
            type={TButton.success}
            text={switchArchiveButtonText}
            icon={TButtonIcon.history} />
        </PageHeader>

        <section id="main-content">

          <div className="instructors-list">
            { this.props.instructors.map((instr: IInstructor) => {
                if (instr.archive !== this.props.isArchive || !instr.id) {
                  return null;
                }

                return <InstructorCard instr={instr} handleEditClick={this.handleEditClick} key={instr.id} />
              }) }
          </div>

          <BlockLoadAnimation dataLoading={this.props.isUpdating} />
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  instructors: state.instructors.instructors,
  isUpdating: state.instructors.isUpdating
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  getInstructors: () => dispatch(getInstructorsAction()),
  redirect: (path: string) => dispatch(redirectAction(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(InstructorsContainer);