import axios from '../config/axios';
import moment from 'moment';
import { IAccessToken } from '../types/authTypes'

export const saveRefreshToken = (token: string) => {
  localStorage.setItem('rt', token);
}


export const getRefreshToken = () => {
  return localStorage.getItem('rt');
}


export const deleteRefreshToken = () => {
  return localStorage.removeItem('rt');
}


export const saveAccessToken = (token: IAccessToken) => {
  localStorage.setItem('at', JSON.stringify({
    ...token,
    expiresAt: token.expiresAt *= 1000    // ms
  }));
}


export const getAccessToken = () => {
  try {
    return JSON.parse(localStorage.getItem('at') || "");
  } catch (err) {
    return {};
  }
}


export const deleteAccessToken = () => {
  return localStorage.removeItem('at');
}


export const checkAccessToken = () => {
  const token: IAccessToken = getAccessToken();

  if (!token || !token.token || !token.expiresAt) {
    return false;
  }

  // expiresAt + 5 sec
  if (moment().diff(token.expiresAt) + 5000 >= 0) {
    return false;
  }

  return true;
}


export const renewAccessToken = () => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    throw new Error('Refresh token not found.');
  }

  return axios.post('/auth/token', { token: refreshToken })
    .then(result => {
      const accessToken: IAccessToken = result.data.accessToken;

      return accessToken;
    }).catch(err => {
      deleteRefreshToken();

      throw new Error(err.message);
    });
}
