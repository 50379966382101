import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState, IPage } from '../../types/storeTypes';
import { getPagesAction } from '../../store/pageActions';
import { TButton, TButtonIcon } from '../../types/formTypes';
import { redirectAction } from '../../store/appActions';

import PageHeader from '../template/PageHeader';
import LinkButton from '../UI/LinkButton';
import BlockLoadAnimation from '../UI/load-animation/BlockAnimation';
import PagesListItem from './PagesListItem';

import './Pages.scss';

type Props = {
  pages: IPage[],
  isUpdating: boolean
  getPages(): void
  redirect(path: string): void
};

type State = {};

class Pages extends React.Component<Props, State> {
  componentDidMount = () => {
    if (this.props.pages.length <= 1) {
      this.props.getPages();
    }
  }

  handleEditClick = (id: number | null) => {
    if (!id) return;

    this.props.redirect(`/pages/${id}/edit`);
  }

  render() {
    return (
      <React.Fragment>
        <PageHeader title="Страницы">
          <LinkButton
            link="/pages/add"
            type={TButton.success}
            text="Добавить"
            icon={TButtonIcon.add} />
        </PageHeader>

        <section id="main-content" className="card">
          <table className="table table-hover table-sm pages-table">
            <thead>
              <tr>
                <th>Заголовок</th>
                <th>Изменен</th>
              </tr>
            </thead>
            <tbody className="">
              { this.props.pages && this.props.pages.map(item => (
                <PagesListItem key={item.page_id || undefined} data={item} handleEditClick={this.handleEditClick} />
              ))}
            </tbody>
          </table>

          <BlockLoadAnimation dataLoading={this.props.isUpdating} />
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  pages: state.pages.pages,
  isUpdating: state.pages.page_updating
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  getPages: () => dispatch(getPagesAction()),
  redirect: (path: string) => dispatch(redirectAction(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pages);