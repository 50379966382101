import React from 'react';

import './BlockAnimation.scss';

type Props = {
  dataLoading: boolean
}

const BlockLoadAnimation:React.FC<Props> = props => {
  const { dataLoading } = props;

  if (!dataLoading) {
    return null;
  }

  return (
    <div className='block-load-animation'>
      <div className="load-animation-gif"></div>
    </div>
  )
}

export default BlockLoadAnimation;