import { IPagesState, IPageActions } from '../types/pagesTypes';
import * as actionTypes from '../constants/actionTypes';

const initialState: IPagesState = {
  pages: [],
  page: null,
  page_updating: false
}

export default (state = initialState, action: IPageActions) => {
  switch (action.type) {
    case actionTypes.PAGES_UPDATING:
      return {
        ...state,
        page_updating: true
      }

    case actionTypes.PAGES_UPDATED:
      return {
        ...state,
        page_updating: false
      }

    case actionTypes.PAGES_RECEIVED:
      return {
        ...state,
        pages: action.pages
      }

    case actionTypes.PAGE_RECEIVED:
      return {
        ...state,
        page: action.page
      }

    case actionTypes.PAGE_ADDED:
     return {
       ...state,
       pages: [action.page].concat(state.pages)
     }

    case actionTypes.PAGE_UPDATED:
     return {
       ...state,
       pages: state.pages.map(page => {
         if (page.page_id === action.page.page_id) {
           return action.page;
         }

         return page;
       })
     }

    case actionTypes.PAGE_REMOVED:
     return {
       ...state,
       pages: state.pages.filter(page => {
         return page.page_id !== action.page_id;
       })
     }

    case actionTypes.CLEAR_PAGE_DATA:
      return {
        ...state,
        page: null
      }

    default:
      return state;
  }
}