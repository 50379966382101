import axios from '../config/axios';
import { Dispatch } from 'redux';
import * as actionTypes from '../constants/actionTypes';
import { errorHandle } from './errorHandle';
// import { IPage } from '../types/pagesTypes';
// import { addNotification } from './notificationActions';
// import { NotificationTypes } from '../types/notificationTypes';

export function getGroupBasePriceAction() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.GROUP_BASE_PRICE_UPDATING });

    axios.get('/group-base-price')
      .then((result) => {
        dispatch({
          type: actionTypes.GROUP_BASE_PRICE_RECEIVED,
          price: result.data.price
        });
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.GROUP_BASE_PRICE_UPDATED });
      })
  }
}