import axios from '../config/axios';
import _ from 'lodash';
import * as actionTypes from '../constants/actionTypes';
import { errorHandle } from './errorHandle';
import { Dispatch } from 'redux';
import { IInstructorEditData } from '../types/instructorsTypes';
import { addNotification } from './notificationActions';
import { NotificationTypes } from '../types/notificationTypes';

export function getInstructorsAction() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.INSTRUCTORS_DATA_IS_UPDATING });

    axios.get('/instructors')
      .then((result) => {
        dispatch({
          type: actionTypes.ALL_INSTRUCTORS_RECEIVED,
          instructors: result.data
        });
      })
      .catch((err) => {
        dispatch({ type: actionTypes.CLEAR_INSTRUCTORS });
        dispatch(errorHandle(err));
      });
  }
}


export function getInstructorAction(id: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.INSTRUCTORS_DATA_IS_UPDATING });

    axios.get(`/instructors/${id}`)
      .then((result) => {
        dispatch({
          type: actionTypes.INSTRUCTOR_RECEIVED,
          instructor: result.data
        });
      })
      .catch((err) => {
        dispatch(errorHandle(err));
        dispatch(clearInstructorDataAction());
      });
  }
}


export function clearInstructorDataAction() {
  return { type: actionTypes.CLEAR_INSTRUCTOR };
}


export function clearInstructorsDataAction() {
  return { type: actionTypes.CLEAR_INSTRUCTORS };
}


export function updateInstructorAction(id: number, data: IInstructorEditData) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.INSTRUCTORS_DATA_IS_UPDATING });

    let form = createFormData(data);
    form.append('_method', "patch");

    axios.post(`/instructors/${id}`, form)
      .then((result) => {
        dispatch({
          type: actionTypes.INSTRUCTOR_RECEIVED,
          instructor: result.data
        });

        dispatch(addNotification(NotificationTypes.success, "Инструктор сохранен."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.INSTRUCTORS_DATA_UPDATED });
      })
  }
}


export function addInstructorAction(data: IInstructorEditData) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.INSTRUCTORS_DATA_IS_UPDATING });

    let form = createFormData(data);

    axios.post(`/instructors`, form)
      .then((result) => {
        dispatch({ type: actionTypes.INSTRUCTOR_ADDED, instructor: result.data });
        dispatch(addNotification(NotificationTypes.success, "Инструктор сохранен."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      });
  }
}

export function removeInstructorAction(id: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.PAGE_LOADING });

    axios.delete(`/instructors/${id}`)
      .then((result) => {
        dispatch(clearInstructorDataAction());
        dispatch({ type: actionTypes.INSTRUCTOR_REMOVED, id });

        dispatch({type: actionTypes.SET_REDIRECT, path: '/instructors'});
        dispatch(addNotification(NotificationTypes.success, "Инструктор удален."));
      })
      .catch((err) => {
        dispatch(errorHandle(err));
      })
      .then(() => {
        dispatch({ type: actionTypes.PAGE_LOADED });
      });
  }
}

function createFormData(data: IInstructorEditData): FormData {
  let form = new FormData();

  Object.keys(data).forEach(key => {
    if (["id", "photo", "image"].indexOf(key) !== -1) return;

    form.append(key, data[key]);
  });

  // append image
  if (data.image !== null) {
    form.append("image", data.image);
  }

  // set photo only if we need to remove it
  if (!data.photo && data.image === null) {
    form.append("photo", "");
  }

  return form;
}