import React from 'react';
import { connect } from 'react-redux';
import { IPage } from '../../types/pagesTypes';
import { IAppState } from '../../types/storeTypes';
import { ThunkDispatch } from 'redux-thunk';

import BlockLoadAnimation from '../UI/load-animation/BlockAnimation';
import PageHeader from '../template/PageHeader';
import Button from '../UI/form/Button';
import PageForm from './PageForm';

import {
  getPageAction,
  addPageAction,
  updatePageAction,
  removePageAction,
  clearPageDataAction } from '../../store/pageActions';
import { redirectAction } from '../../store/appActions';
import { TButton, TButtonIcon, TButtonIconPosition } from '../../types/formTypes';

type Props = {
  match: any
  history: any
  page: IPage | null,
  isUpdating: boolean
  getPage(id: number): void
  addPage(page: IPage): void
  updatePage(id: number, page: IPage): void
  removePage(id: number): void
  redirect(path: string): void
  clearPageData(): void
}

type State = {
  page: IPage,
  status: Statuses
}

enum Statuses {idle, fetching, saving, removing};

class PageEditContainer extends React.Component<Props, State> {
  readonly state: State = {
    page: {
      page_id: null,
      page_title: "",
      page_content: "",
      page_last_modified: undefined,
      page_date_publish: undefined,
      page_slug: ""
    },
    status: Statuses.idle,
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if (!id) return;

    this.setState({
      page: {...this.state.page, page_id: id},
      status: Statuses.fetching
    })

    this.props.getPage(id);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.page === null) return;

    const { status } = this.state;

    // page data is received
    if (status === Statuses.fetching) {
      this.setState({
        page: this.props.page,
        status: Statuses.idle
      });
    }

    // page was added/updated
    if (status === Statuses.saving && !this.props.isUpdating) {
      this.setState({
        page: this.props.page,
        status: Statuses.idle
      });
    }

    // page was removed
    if (status === Statuses.removing && !this.props.isUpdating) {
      this.setState({
        status: Statuses.idle
      });

      setTimeout(() => {
        this.props.redirect('/pages');
      }, 50);
    }
  }

  componentWillUnmount = () => {
    this.props.clearPageData();
  }

  handleChangeInput = (e: any) => {
    const target = e.target;
    const { page } = this.state;

    if (!page.hasOwnProperty(target.name)) return;

    this.setState({
      page: {
        ...page,
        [target.name]: target.value
      }
    });
  }

  handleSubmitForm = (e: any) => {
    e.preventDefault();

    const { page_id, page_title, page_content } = this.state.page;

    if (!page_title) return;

    const data: IPage = {
      page_id,
      page_title,
      page_content
    }

    this.setState({
      status: Statuses.saving
    });

    if (page_id === null) {
      this.props.addPage(data);
    } else {
      this.props.updatePage(page_id, data);
    }
  }

  handleRemove = () => {
    if (this.state.page.page_id === null) return;

    this.setState({
      status: Statuses.removing
    });

    this.props.removePage(this.state.page.page_id);
  }

  render() {
    const { status } = this.state;
    const { page_id } = this.state.page;

    return (
      <React.Fragment>
        <PageHeader title={(!page_id ? "Добавить" : "Редактировать") + " страницу"}>
          {status !== Statuses.fetching && <Button
            type={TButton.success}
            text="Сохранить"
            icon={ status === Statuses.saving ? TButtonIcon.loading : TButtonIcon.tick }
            iconPosition={TButtonIconPosition.left}
            clickHandle={this.handleSubmitForm} />
          }

          {status !== Statuses.fetching && page_id && <Button
            type={TButton.danger}
            text="Удалить"
            icon={ status === Statuses.removing ?  TButtonIcon.loading :  TButtonIcon.cross }
            iconPosition={TButtonIconPosition.left}
            clickHandle={this.handleRemove} />
          }

          <Button
            type={TButton.default}
            text="Назад"
            icon={TButtonIcon.undo}
            iconPosition={TButtonIconPosition.left}
            clickHandle={this.props.history.goBack} />

        </PageHeader>

        <section id="main-content" className='card fill-height'>
          {status !== Statuses.fetching && <PageForm
            page={this.state.page}
            handleSubmit={this.handleSubmitForm}
            handleChangeInput={this.handleChangeInput} />
          }

          <BlockLoadAnimation dataLoading={status === Statuses.fetching} />
        </section>

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({
  page: state.pages.page,
  isUpdating: state.pages.page_updating
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  getPage: (id: number) => dispatch(getPageAction(id)),
  addPage: (data: IPage) => dispatch(addPageAction(data)),
  updatePage: (id: number, data: IPage) => dispatch(updatePageAction(id, data)),
  removePage: (id: number) => dispatch(removePageAction(id)),
  redirect: (path: string) => dispatch(redirectAction(path)),
  clearPageData: () => dispatch(clearPageDataAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(PageEditContainer);