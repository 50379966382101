import React from 'react';
import moment from 'moment';
import PageHeader from './template/PageHeader';

import './Build.scss';

type Props = {};

const Build: React.FC<Props> = (props) => {
  const d = moment.unix(Number.parseInt(process.env.REACT_APP_BUILD_TIME || ''));

  const branch = process.env.REACT_APP_GIT_BRANCH;
  const build = process.env.REACT_APP_BUILD;
  const commit = process.env.REACT_APP_GIT_COMMIT;

  return (
    <React.Fragment>
      <PageHeader title="Build status" />

      <section id="main-content" className='card fill-height'>
        <div id="build-status">
          <div><strong>Version:</strong> {process.env.REACT_APP_VERSION}</div>
          <div><strong>Build:</strong> {branch}-{build}/{d.format('YYYY-MM-DD\THH:mm:ss')}</div>
          <div><strong>Commit:</strong> {commit}</div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Build;