import React from 'react';
import { IStyle, IAppState } from '../../types/storeTypes';

import {
  updateStyleAction,
  addStyleAction,
  removeStyleAction
} from '../../store/styleActions';

import StyleTitle from './StyleTitle';
import StyleTitleEdition from './StyleTitleEdition';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { addNotification } from '../../store/notificationActions';
import { NotificationTypes } from '../../types/notificationTypes';


type Props = {
  style: IStyle
  countGroups: number
  newStyleAdded(): void
  updateStyle(id: number, title: string): void
  addStyle(title: string): void
  removeStyle(id: number): void
  notification(type: NotificationTypes, message: string): void
};

type State = {
  newTitle: string | null
  styleIsSaving: boolean
  styleIsRemoving: boolean
};

class Style extends React.Component<Props, State> {
  readonly state: State = {
    newTitle: null,
    styleIsSaving: false,
    styleIsRemoving: false
  }

  componentDidMount = () => {
    if (this.props.style.id === null) {
      this.onEditHandler();
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    const { style: newStyle } = this.props;
    const { style: oldStyle } = prevProps;
    const { styleIsSaving } = this.state;


    if (styleIsSaving && oldStyle.title !== newStyle.title) {
      this.setState({
        newTitle: null,
        styleIsSaving: false
      });
    }
  }

  // open form to edit style title
  onEditHandler = () => {
    this.setState({newTitle: this.props.style.title});
  }

  // update style title
  onSaveHandler = (e: React.MouseEvent | React.FormEvent, id: number | null, title: string) => {
    e.preventDefault();

    if (title === "") {
      this.onCancelHandler();
      return;
    }

    if (this.props.style.title === title) {
      this.setState({newTitle: null});

      return;
    }

    if (id === null) {
      this.props.addStyle(title);
    } else {
      this.props.updateStyle(id, title);
    }

    this.setState({styleIsSaving: true});
  }

  // cancel to update style title
  onCancelHandler = () => {
    const { style, newStyleAdded } = this.props;

    this.setState({newTitle: null});

    if (style.id === null) {
      newStyleAdded();
    }
  }

  // remove style
  onRemoveHandler = (e: React.MouseEvent, id: number | null) => {
    e.stopPropagation();

    if (id === null) {
      return;
    }

    if (this.props.countGroups > 0) {
      this.props.notification(
        NotificationTypes.error,
        'Не могу удалить стиль содержащий группы.'
      );

      return;
    }

    const confirm = window.confirm(`Удалить стиль ${this.props.style.title}?`);

    if (!confirm) {
      return;
    }

    this.setState({styleIsRemoving: true});
    this.props.removeStyle(id);
  }

  render() {
    const { style, countGroups } = this.props;

    return (
      <React.Fragment>

        { /* Style header */ }
        { this.state.newTitle === null &&
          <StyleTitle
            style={style}
            notEmpty={countGroups > 0}
            styleIsRemoving={this.state.styleIsRemoving}
            onEditHandler={this.onEditHandler}
            onRemoveHandler={(e) => this.onRemoveHandler(e, style.id)} />}

        { /* Style header edition */ }
        { this.state.newTitle !== null &&
          <StyleTitleEdition
            style={style}
            styleIsSaving={this.state.styleIsSaving}
            onSaveHandler={this.onSaveHandler}
            onCancelHandler={this.onCancelHandler} />}

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: IAppState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  updateStyle: (id: number, title: string) => dispatch(updateStyleAction(id, title)),
  addStyle: (title: string) => dispatch(addStyleAction(title)),
  removeStyle: (id: number) => dispatch(removeStyleAction(id)),
  notification: (type: NotificationTypes, message: string) => dispatch(addNotification(type, message))
});

export default connect(mapStateToProps, mapDispatchToProps)(Style);